import { Component, Input, OnInit } from '@angular/core';
import { async } from '@angular/core/testing';
import { ModalController } from '@ionic/angular';
import { Albaran } from '../../interfaces/albaranes.inter';
import { ModalContadorComponent } from '../modal-contador/modal-contador.component';

@Component({
  selector: 'app-registro-albaranes-sbn',
  templateUrl: './registro-albaranes-sbn.component.html',
  styleUrls: ['./registro-albaranes-sbn.component.scss'],
})
export class RegistroAlbaranesSbnComponent implements OnInit {

  constructor( private modalCtrl: ModalController) { }
  @Input()albaranes: Albaran[] = []
  ngOnInit() {
  }

  enviar(){
    this.modalCtrl.dismiss(this.albaranes);
  }

  async seleccionarContador(alb: Albaran){
    const modal = await this.modalCtrl.create({
      component: ModalContadorComponent,
      cssClass: 'autonomo-modal'
    });
    await modal.present();
    const { data } = await modal.onDidDismiss();
    if (data){
      this.albaranes.forEach( a => {
        a.contador = data
      });
    }
  }
  cerrar(){
    this.modalCtrl.dismiss();
  }

}
