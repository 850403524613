import { Component, OnInit } from '@angular/core';
import { Cliente } from '../../interfaces/cliente.inter';
import { ModalController } from '@ionic/angular';
import { UiSereviceService } from '../../services/ui-serevice.service';
import { ClientesService } from '../../services/clientes.service';
import { FacturasService } from '../../services/facturas.service';
import { EmpresasService } from '../../services/empresas.service';

@Component({
  selector: 'app-modal-clientes',
  templateUrl: './modal-clientes.component.html',
  styleUrls: ['./modal-clientes.component.scss'],
})
export class ModalClientesComponent implements OnInit {

  clientes: Cliente[] = [];
  buscarText: string;
  constructor(private modalController: ModalController,
              private clienteService: ClientesService,
              private empresaService: EmpresasService,
              private facturaService: FacturasService,
              private ui: UiSereviceService) { }

  async ngOnInit() {
    this.clienteService.pag = 0;
      const resp = await this.clienteService.getClientesActivos(true);
      if (resp.ok){
        this.clientes = resp.clientes;
      } else {
        this.ui.alertError('No se han cargado los datos desde el backen: Error Clientes');
      }
  }

  async buscar(event){
    const resp = await this.clienteService.buscarClientes(event.detail.value);
    if (resp.ok) {
      this.clientes = resp.clientes;
    } else {
      this.ui.presentToast('Error: Filtro de clientes no aplicado');
    }
  }

  cerrar(){
    this.modalController.dismiss();
  }
  async select(cli: Cliente){
    if (!cli.prefijoFactura){
      this.ui.alertError('Cliente sin prefijo de factura, no puede continuar.');
      return
    }
    const totalCliente = await this.facturaService.calcularLimite(cli._id, 0);
    const empresa = await this.empresaService.getEmpresas();

    let limite = empresa.empresas[0].limiteFactura || 0;
    if (cli.limite){
      limite = cli.limite;
    }
    if (totalCliente){
      const resta = limite - totalCliente.total;
      this.ui.alertInfo(`Total facturado: ${totalCliente.total} € <br>
      Límite: ${limite} € <br>
      Pendiente: ${resta} € `, cli.nombre);
    }
    this.modalController.dismiss(cli);
  }

  async cargaMasDatos(event: any){
    const clientes = await this.clienteService.getClientesActivos(true);
    if (!clientes.ok){
      this.ui.alertError('Error al cargar los datos');
      return;
    }
    this.clientes.push(...clientes.clientes);
    if (event) {
      event.target.complete();
    }
    
    if (clientes.clientes.length === 0) {
      event.target.disabled = true;
      this.clienteService.pag = 0;
    }

  }

}
