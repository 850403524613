import { Component, OnInit } from '@angular/core';
import { LoginService } from '../../services/login.service';
import { NavController, ModalController } from '@ionic/angular';
import { UiSereviceService } from '../../services/ui-serevice.service';


@Component({
  selector: 'app-login',
  templateUrl: './login.page.html',
  styleUrls: ['./login.page.scss'],
})
export class LoginPage implements OnInit {

  loginUser = {
    user: '',
    pass: ''
  };

  loginImg = '../../src/asset/login.png';

  constructor(private loginService: LoginService,
              private navController: NavController,
              private modalController: ModalController,
              private ui: UiSereviceService) { }

  async ngOnInit() {
    const resp: any = await this.loginService.validarUsuario().catch(err => {
      this.ui.presentToast(err);
    });

    if (resp.ok){

      this.navController.navigateRoot('/inicio');
    }
  }

  async login(){
    const ok = await this.loginService.loginUser(this.loginUser.user, this.loginUser.pass);
    if (!ok){
      this.loginUser.user = '';
      this.loginUser.pass = '';
      this.ui.alertError('Usuario o contraseña incorrecta');
    } else {
      this.modalController.dismiss(true);
    }
  }
}
