import { Component, OnInit } from '@angular/core';
import { Empresa } from '../../interfaces/empresa.inter';
import { ModalController } from '@ionic/angular';
import { EmpresasService } from '../../services/empresas.service';
import { UiSereviceService } from 'src/app/services/ui-serevice.service';

@Component({
  selector: 'app-modal-empresa',
  templateUrl: './modal-empresa.component.html',
  styleUrls: ['./modal-empresa.component.scss'],
})
export class ModalEmpresaComponent implements OnInit {

  empresas: Empresa[] = [];
  buscarText: string;
  constructor(private modalController: ModalController,
              private empresaService: EmpresasService,
              private ui: UiSereviceService) { }

  async ngOnInit() {
      const resp = await this.empresaService.getEmpresas();
      if (resp.ok){
        this.empresas = resp.empresas;
      } else {
        this.ui.alertError('No se han cargado los datos desde el backen: Error Empresas');
      }
  }

  buscar(event){
    this.buscarText = event.detail.value;
  }

  cerrar(){
    this.modalController.dismiss();
  }
  select(emp: Empresa){
    this.modalController.dismiss(emp);
  }
}
