import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { NuevaLineaPage } from '../../pages/albaranes/nueva-linea/nueva-linea.page';
import { ModalClientesComponent } from '../modal-clientes/modal-clientes.component';
import { UiSereviceService } from '../../services/ui-serevice.service';
import { FacturasService } from '../../services/facturas.service';

@Component({
  selector: 'app-registro-sabana',
  templateUrl: './registro-sabana.component.html',
  styleUrls: ['./registro-sabana.component.scss'],
})
export class RegistroSabanaComponent implements OnInit {

  constructor( private modalCtrl: ModalController,
               private facturaService: FacturasService,
               private ui: UiSereviceService) { }

  aux: any = {};
  ngOnInit() {}

  async clienteSelect(){
    /**
     * async clienteSelect(){
    const modal = await this.modalCtrl.create({
      component: ModalClientesComponent,
      cssClass: 'autonomo-modal'
    });
    await modal.present();
    const { data } = await modal.onDidDismiss();
    if (data){
      const totalCliente = await this.facturaService.calcularLimite(data._id, 0);
      if (totalCliente){
        this.ui.alertInfo('Total facturado: ' + totalCliente.total + ' €');
      }
      this.albaran.cliente = data;
    }
  }
     */
    const modal = await this.modalCtrl.create({
      component: ModalClientesComponent,
      cssClass: 'autonomo-modal',
      backdropDismiss: false,
    });

    await modal.present();
    const { data } = await modal.onWillDismiss();
    if (data){
      if (!data.prefijoFactura){
        this.ui.alertError('Cleinte sin prefijo de factura');
      // this.albaran.cliente = data;
      } else {
        this.aux.cliente = data;
      }
    }
  }
  async articuloSelect(){
    const modal = await this.modalCtrl.create({
      component: NuevaLineaPage,
      cssClass: 'lineSab-modal',
      backdropDismiss: false,
      componentProps: {
        sabana: true
      }
    });

    await modal.present();
    const { data } = await modal.onWillDismiss();
    if(data){
      this.aux.articulo = data;
    }
  }

  enviar(){
    this.modalCtrl.dismiss(this.aux);
  }
  cerrar(){
    this.modalCtrl.dismiss();
  }

}
