import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { RespConfig, IConfig } from '../interfaces/config.inter';

const URL = environment.urlBackend;

@Injectable({
  providedIn: 'root'
})
export class ConfigService {

  headers = {
    token: ''
  };

  constructor(private http: HttpClient,
              private storage: Storage) { }

  private async cargarToken() {
    this.headers.token = await this.storage.get('token');
  }

  async cargarConfig(){
    await this.cargarToken();
    return new Promise<RespConfig>(resolve => {
      this.http.get(`${URL}/config`, {headers: this.headers}).subscribe((resp: RespConfig) => {
        resolve(resp);
      });
    });
  }

  async crearConfig(config: IConfig){
    await this.cargarToken();
    return new Promise<RespConfig>(resolve => {
      this.http.post(`${URL}/config`, config, {headers: this.headers}).subscribe((resp: RespConfig) => {
        resolve(resp);
      });
    });
  }

  async actualizaConfig(config: IConfig){
    await this.cargarToken();
    return new Promise<RespConfig>(resolve => {
      this.http.put(`${URL}/config`, config, {headers: this.headers}).subscribe((resp: RespConfig) => {
        resolve(resp);
      });
    });
  }

  async verificaPass(pass: string){
    await this.cargarToken();
    return new Promise<boolean>(resolve => {
      this.http.post(`${URL}/config/pass`, {pass}, {headers: this.headers}).subscribe((resp: RespConfig) => {
        resolve(resp.ok);
      });
    });
  }

}
