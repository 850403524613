import { Pipe, PipeTransform } from '@angular/core';
import { Autonomo } from '../interfaces/autonomo.inter';

@Pipe({
  name: 'buscarNombre'
})
export class BuscarNombrePipe implements PipeTransform {

  transform(buscar: any[], text: string): Autonomo[] {

    if (text) { text = text.toLowerCase(); }
    if (!text){
      return buscar;
    } else {
      return buscar.filter(busqueda => {
        // tslint:disable-next-line: max-line-length
        return busqueda.nombre.toLowerCase().includes(text) || busqueda.apellido1.toLowerCase().includes(text) || busqueda.apellido2.toLowerCase().includes(text)
        || busqueda.email.toLowerCase().includes(text);
      });
    }
  }
}
