import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { MenuComponent } from './menu/menu.component';
import { HeaderModalComponent } from './header-modal/header-modal.component';
import { PopButtonsComponent } from './pop-buttons/pop-buttons.component';
import { ModalFechasComponent } from './modal-fechas/modal-fechas.component';
import { FormsModule } from '@angular/forms';
import { ModalAutonomosComponent } from './modal-autonomos/modal-autonomos.component';
import { PipesModule } from '../pipes/pipes.module';
import { ModalClientesComponent } from './modal-clientes/modal-clientes.component';
import { ModalEmpresaComponent } from './modal-empresa/modal-empresa.component';
import { HuecosFacuraComponent } from './huecos-facura/huecos-facura.component';
import { PopConfigComponent } from './pop-config/pop-config.component';
import { FacturaComponent } from './factura/factura.component';
import { AlbaranComponent } from './albaran/albaran.component';
import { CargaGruComponent } from './carga-gru/carga-gru.component';
import { ModalAlmacenComponent } from './modal-almacen/modal-almacen.component';
import { ModalCargasGruComponent } from './modal-cargas-gru/modal-cargas-gru.component';
import { ModalConfigComponent } from './modal-config/modal-config.component';
import { NuevaNotificacionComponent } from './nueva-notificacion/nueva-notificacion.component';
import { ModalPushActivosComponent } from './modal-push-activos/modal-push-activos.component';
import { ModalNotificacionComponent } from './modal-notificacion/modal-notificacion.component';
import { NuevoAlbaranComponent } from './nuevo-albaran/nuevo-albaran.component';
import { ModalContadorComponent } from './modal-contador/modal-contador.component';
import { RegistroSabanaComponent } from './registro-sabana/registro-sabana.component';
import { RegistroAlbaranesSbnComponent } from './registro-albaranes-sbn/registro-albaranes-sbn.component';
import { SabanaComponent } from './sabana/sabana.component';



@NgModule({
  declarations: [
    HeaderComponent,
    FooterComponent,
    MenuComponent,
    HeaderModalComponent,
    PopButtonsComponent,
    ModalFechasComponent,
    ModalAutonomosComponent,
    ModalClientesComponent,
    ModalEmpresaComponent,
    HuecosFacuraComponent,
    PopConfigComponent,
    FacturaComponent,
    AlbaranComponent,
    CargaGruComponent,
    ModalAlmacenComponent,
    ModalCargasGruComponent,
    ModalConfigComponent,
    NuevaNotificacionComponent,
    ModalPushActivosComponent,
    ModalNotificacionComponent,
    NuevoAlbaranComponent,
    ModalContadorComponent,
    RegistroSabanaComponent,
    RegistroAlbaranesSbnComponent,
    SabanaComponent
    ],
  imports: [
    FormsModule,
    CommonModule,
    IonicModule,
    PipesModule,
  ], exports: [
    HeaderComponent,
    FooterComponent,
    MenuComponent,
    HeaderModalComponent,
    PopButtonsComponent,
    ModalFechasComponent,
    ModalAutonomosComponent,
    ModalClientesComponent,
    ModalEmpresaComponent,
    PopConfigComponent,
    CargaGruComponent,
    ModalAlmacenComponent,
    ModalCargasGruComponent,
    NuevaNotificacionComponent,
    ModalPushActivosComponent,
    ModalNotificacionComponent,
    SabanaComponent
  ]
})
export class ComponentsModule { }
