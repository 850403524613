import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { AutonomoResp, Autonomo } from '../interfaces/autonomo.inter';

const URL = environment.urlBackend;

@Injectable({
  providedIn: 'root'
})
export class AutonomosService {

  headers = {
    token: ''
  };

  constructor(private storage: Storage, private http: HttpClient) { }

  private async cargarToken() {
    this.headers.token = await this.storage.get('token');
  }

  async getAutonomos(estado: boolean): Promise<AutonomoResp> {
    return new Promise<AutonomoResp>(async resolve => {
      await this.cargarToken();
      this.http.get(`${URL}/autonomos/estado/${estado}`, { headers: this.headers }).subscribe((autonomoResp: AutonomoResp) => {
          resolve(autonomoResp);
        });
    });
  }

  async getAutonomo(autonomoId: any): Promise<AutonomoResp> {
    return new Promise<AutonomoResp>(async resolve => {
      await this.cargarToken();
      this.http.get(`${URL}/autonomo/${autonomoId._id}`, { headers: this.headers }).subscribe((autonomoResp: AutonomoResp) => {
          resolve(autonomoResp);
        });
    });
  }

  async guardarArutonomo(autonomo: Autonomo) {
    await this.cargarToken();
    return new Promise<AutonomoResp>(async resolve => {
      this.http.post(`${URL}/autonomos`, autonomo, { headers: this.headers }).subscribe((autonomoResp: AutonomoResp) => {
        resolve(autonomoResp);
      });
    });
  }

  async actualizaAutonomo(autonomo: Autonomo) {
    await this.cargarToken();
    return new Promise<AutonomoResp>(async resolve => {
      this.http.put(`${URL}/autonomos/${autonomo._id}`, autonomo, { headers: this.headers }).subscribe((autonomoResp: AutonomoResp) => {
        resolve(autonomoResp);
      });
    });
  }

  async cambiaEstadoAutonomo(autonomoID: string, estado: boolean) {
    await this.cargarToken();
    return new Promise<AutonomoResp>(async resolve => {
      // tslint:disable-next-line: max-line-length
      this.http.put(`${URL}/autonomos/${autonomoID}`, {estado}, { headers: this.headers }).subscribe((autonomoResp: AutonomoResp) => {
        resolve(autonomoResp);
      });
    });
  }

  async eliminarAutonomo(autonomoID: string) {
    await this.cargarToken();
    return new Promise<AutonomoResp>(async resolve => {
      // tslint:disable-next-line: max-line-length
      this.http.delete(`${URL}/del/autonomos/${autonomoID}`, { headers: this.headers }).subscribe((autonomoResp: AutonomoResp) => {
        resolve(autonomoResp);
      });
    });
  }

  async buscarAutonomo(text: string){
    return new Promise<AutonomoResp>(async resolve => {
      await this.cargarToken();
      if ( !text || text.length < 1) {
        this.http.get(`${URL}/autonomos/estado/true`, { headers: this.headers }).subscribe((autonomos: AutonomoResp) => {
          resolve(autonomos);
        });
      } else {
        this.http.get(`${URL}/autonomos/buscar/${text}`, { headers: this.headers }).subscribe((autonomos: AutonomoResp) => {
          resolve(autonomos);
        });
      }
    });
  }
}
