import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { ArticulosResp, Articulo } from '../interfaces/articulo.inter';


const URL = environment.urlBackend;

@Injectable({
  providedIn: 'root'
})
export class ArticulosService {

  headers = {
    token: ''
  };

  constructor(private storage: Storage, private http: HttpClient) { }

  private async cargarToken() {
    this.headers.token = await this.storage.get('token');
  }

  getArticulos(){
    // tslint:disable-next-line: no-shadowed-variable
    return new Promise<ArticulosResp>(async resolve => {
      await this.cargarToken();
      this.http.get(`${URL}/articulos`, {headers: this.headers}).subscribe((artResp: ArticulosResp) => {
        resolve(artResp);
      });
    });
  }

  getArticulo(art?: string){
    if (art){
      // tslint:disable-next-line: no-shadowed-variable
      return new Promise<ArticulosResp>(async resolve => {
        await this.cargarToken();
        this.http.get(`${URL}/articulo/${art}`, {headers: this.headers}).subscribe((artResp: ArticulosResp) => {
          resolve( artResp);
        });
      });
    }
  }

  buscarArticulo(text: string){
    // tslint:disable-next-line: no-shadowed-variable
    return new Promise<ArticulosResp>(async resolve => {
      this.http.get(`${URL}/articulos/buscar/${text}`, {headers: this.headers}).subscribe((artResp: ArticulosResp) => {
        resolve(artResp);
      });
    });
  }

  guardarArticulo(art: Articulo, edita: boolean){
    // tslint:disable-next-line: no-shadowed-variable
    return new Promise<ArticulosResp>(async resolve => {
      await this.cargarToken();
      if (edita){
        this.http.put(`${URL}/articulos/${art._id}`, art, {headers: this.headers}).subscribe((artResp: ArticulosResp) => {
          resolve(artResp);
        });
      } else {
        this.http.post(`${URL}/articulos`, art, {headers: this.headers}).subscribe((artResp: ArticulosResp) => {
          resolve(artResp);
        });
      }
    });
  }

  eliminaArticulo(artId: string){
    // tslint:disable-next-line: no-shadowed-variable
    return new Promise<boolean>(async resolve => {
      await this.cargarToken();
      this.http.delete(`${URL}/articulos/${artId}`, {headers: this.headers}).subscribe((artResp: ArticulosResp) => {
        resolve(artResp.ok);
      });
    });
  }
}
