import { Injectable, APP_INITIALIZER } from '@angular/core';
import { Storage } from '@ionic/storage';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Usuario, UsuarioResp } from '../interfaces/usuario.inter';
import { AppComponent } from '../app.component';



const URL = environment.urlBackend;

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  constructor( private storage: Storage, private http: HttpClient) { }

  headers = {
    token: ''
  };

  usuario: Usuario;

  private async cargarHeaders(){
    this.headers.token = await this.storage.get('token');
  }

  loginUser(email: string, pass: string){

    return new Promise( async (resolve) => {

      this.cargarHeaders();

      if (this.headers.token){
        resolve(true);
      }

      this.http.post(`${URL}/login`, {email, password: pass}, {}).subscribe( async (login: any) => {

        if (!login.ok){
          resolve(false);
        }
        await this.storage.set('token', login.token);
        this.headers.token = login.token;
        resolve(true);
      });
    });
  }

  cargarUsuario(){
    return new Promise<Usuario>(async (resolve, rejected) => {
      this.cargarHeaders();

      if (!this.usuario){
        this.usuario = await this.storage.get('usuario');
      }

      this.http.get(`${URL}/usuario/token`, {headers: this.headers}).subscribe(async (usuario: UsuarioResp) => {
        if (!usuario.ok){
          this.headers.token = '';
          await this.storage.remove('token');
          rejected('Error, usuario no cargado');
        } else {
          this.usuario = usuario.usuario;
          await this.storage.set('usuario', usuario.usuario);
          resolve(usuario.usuario);

        }
      });
    });
  }

  validarUsuario(){
    return new Promise(async (resolve: any, rejected: any) => {
      await this.cargarHeaders();

      if (this.headers.token === null){
        resolve(false);
      } else {
        this.http.get(`${URL}/login/validar`, {headers: this.headers}).subscribe((resp: any) => {
          resolve(resp);
        });
      }
    });

  }

  logOutUser(){
    return new Promise((rejected, resolve) => {
      this.headers.token = null;
      this.storage.remove('token').catch((err: any) => {
        rejected('Error: ' + err);
      });
      this.storage.remove('usuario').catch((err: any) => {
        rejected('Error: ' + err);
      });
      location.reload();
      resolve(true);
    });
  }
}
