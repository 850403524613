import { Component, OnInit, Input } from '@angular/core';
import { MenuController, NavController, ModalController } from '@ionic/angular';
import { LoginService } from '../../services/login.service';
import { Usuario } from '../../interfaces/usuario.inter';
import { LoginPage } from '../../pages/login/login.page';
import { UiSereviceService } from '../../services/ui-serevice.service';


@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {

  @Input() titulo = '';
  usuario: Usuario = {};
  constructor(private loginService: LoginService,
              private navController: NavController,
              private modalController: ModalController,
              private ui: UiSereviceService) { }



  ngOnInit() {
    this.loginService.cargarUsuario()
    .then(usuario => {
      this.usuario = usuario;
    })
    .catch(error => {
      this.ui.alertError('Error: no se ha cargado el usuario desde el Backend')
    });
  }

  cerrarSesion(){
    this.loginService.logOutUser();
    this.login();
  }

  async login() {

    const modal = await this.modalController.create({
      component: LoginPage,
      cssClass: '',
      componentProps: {
        estadoLogin: false
      }
    });

    await modal.present();
    const { data } = await modal.onWillDismiss();
    if (data){
      this.navController.navigateRoot('/inicio');
    }
  }

  onClick(menu){
    this.navController.navigateForward(menu.fw);
  }

}
