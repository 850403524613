import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';

const URL = environment.urlBackend;

@Injectable({
  providedIn: 'root'
})

export class EmailService {

  headers = {
    token: ''
  };

  constructor(private storage: Storage, private http: HttpClient) { }

  private async cargarToken() {
    this.headers.token = await this.storage.get('token');
  }

  /*
  async postFile(fileToUpload: File, empresaEmail: string) {
      await this.cargarToken();
      const formData: FormData = new FormData();
      formData.append('image', fileToUpload, fileToUpload.name);
      return new Promise((resolve) => {
        this.http.post(`${URL}/upload/${empresaEmail}`, formData, { headers: this.headers }).subscribe((resp: any) => {
        resolve(resp);
      });
    });
  }
*/
  async enviarMail(asunto: string, texto: string, pdf: any, cliente: string){
    await this.cargarToken();
    const formData: FormData = new FormData();
    formData.append('pdf', pdf);
    formData.append('cliente', 'mbarbero@grupoinova.es');
    formData.append('asunto', 'Nueva autofactura de recogida de aceite a su nombre');
    formData.append('texto', 'Hemos generado una nueva autofatura a su nombre, la cual adjuntamos.');
    return new Promise(resolve => {
      this.http.put(`${URL}/envio`, formData, {headers: this.headers}).subscribe(resp => {
        resolve(resp);
      });
    });
  }
}
