import { Component, OnInit } from '@angular/core';
import { Autonomo, Push } from '../../interfaces/autonomo.inter';
import { ModalController } from '@ionic/angular';
import { UiSereviceService } from '../../services/ui-serevice.service';
import { PushService } from '../../services/push.service';

@Component({
  selector: 'app-modal-push-activos',
  templateUrl: './modal-push-activos.component.html',
  styleUrls: ['./modal-push-activos.component.scss'],
})
export class ModalPushActivosComponent implements OnInit {

  pushs: Push[] = [];
  buscarText: string;
  constructor(private modalController: ModalController,
              private pushService: PushService,
              private ui: UiSereviceService) { }

  async ngOnInit() {
      const resp = await this.pushService.obtenerAutonomos();
      if (resp.ok){
        this.pushs = resp.pushs;
      } else {
        this.ui.alertError('No se han cargado los datos desde el backen: Error Autonomos');
      }
  }

  buscar(event){
    this.buscarText = event.detail.value;
  }

  cerrar(){
    this.modalController.dismiss();
  }

  select(push: Push){
    this.modalController.dismiss(push);
  }
}
