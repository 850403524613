import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Articulo } from 'src/app/interfaces/articulo.inter';
import { ArticulosService } from 'src/app/services/articulos.service';
import { UiSereviceService } from 'src/app/services/ui-serevice.service';
import { GruService } from 'src/app/services/gru.service';

@Component({
  selector: 'app-carga-gru',
  templateUrl: './carga-gru.component.html',
  styleUrls: ['./carga-gru.component.scss'],
})
export class CargaGruComponent implements OnInit {
  carga: number;

  articulo: Articulo = {};
  articulos: Articulo[] = [];
  txtBuscar: string;

  constructor(private modalcontroller: ModalController,
              private articulosService: ArticulosService,
              private ui: UiSereviceService) { }

  async ngOnInit() {

    const resp = await this.articulosService.getArticulos();
    if (resp.ok){
      this.articulos = resp.articulos;
    } else {
      this.ui.alertError('Error: No se han cargado los articulos desde el Backend');
    }
  }

  eventKey(event){
    if (event.keyCode === 13){
        this.guardar();
    }
  }

  selectArt(art: Articulo){
    this.articulo = art;
  }

  bucarArticulo(event){
    this.txtBuscar = event.detail.value;
  }

  cerrar(){
    this.modalcontroller.dismiss();
  }

  guardar(){
    this.modalcontroller.dismiss({articulo: this.articulo, carga: this.carga});
  }
}
