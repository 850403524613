import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { CargaResp } from '../interfaces/gru.inter';

const URL = environment.urlBackend;

@Injectable({
  providedIn: 'root'
})
export class GruService {
  headers = {
    token: ''
  };

  pag = 0;

  constructor(private storage: Storage, private http: HttpClient) { }

  private async cargarToken() {
    this.headers.token = await this.storage.get('token');
  }

  async getCargas(empId, tipo){
    await this.cargarToken();
    return new Promise<CargaResp>(resolve => {
      this.http.get(`${URL}/gru/${empId}/${tipo}`, {headers: this.headers}).subscribe((resp: CargaResp) => {
        resolve(resp);
      });
    });
  }

  async getCargasEmpresa(empId: string, fecha?: Date){
    await this.cargarToken();
    return new Promise<CargaResp>(resolve => {
      this.http.put(`${URL}/gru/${empId}`, {fecha},   {headers: this.headers}).subscribe((resp: CargaResp) => {
        resolve(resp);
      });
    });
  }

  async setCarga(empId, data){
    await this.cargarToken();
    return new Promise<CargaResp>(resolve => {
      // tslint:disable-next-line: max-line-length
      this.http.post(`${URL}/gru/${empId}`, {articulo: data.articulo._id, carga: data.carga},  {headers: this.headers}).subscribe((resp: CargaResp) => {
        resolve(resp);
      });
    });
  }

  async eliminarCarga(idCarga: string){
    await this.cargarToken();
    return new Promise(resolve => {
      this.http.delete(`${URL}/gru/${idCarga}`, {headers: this.headers}).subscribe(resp => {
        resolve(resp);
      });
    });
  }

}
