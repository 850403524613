import { Component, OnInit, Input } from '@angular/core';
import { PopoverController } from '@ionic/angular';

@Component({
  selector: 'app-pop-buttons',
  templateUrl: './pop-buttons.component.html',
  styleUrls: ['./pop-buttons.component.scss'],
})
export class PopButtonsComponent implements OnInit {

  @Input() estado: boolean;

  botonesActivo = [
    {
      nombre: 'Desactivar',
      accion: 'desactivar'
    },
    {
      nombre: 'Nuevo albaran',
      accion: 'nuevoalbaran'
    }
  ];

  botonesDesactivado = [
      {
        nombre: 'Activar',
        accion: 'activar'
      },
      {
        nombre: 'Informes',
        accion: 'informes'
      }
  ];

  constructor(private popoverController: PopoverController) { }

  ngOnInit() {

  }

  onClick(event) {
    this.popoverController.dismiss({
      item: event
    });

  }
}
