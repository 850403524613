import { Pipe, PipeTransform } from '@angular/core';
import { environment } from '../../environments/environment.prod';

const URL = environment.urlBackend;

@Pipe({
  name: 'logoPipe'
})
export class LogoPipePipe implements PipeTransform {

  transform(image: string, empEmail: string): string {
    return `${URL}/logo/${empEmail}/${image}`;
  }
}
