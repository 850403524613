import { Component, OnInit, Input } from '@angular/core';
import { Factura } from 'src/app/interfaces/factura.inter';
import { ModalController, AlertController } from '@ionic/angular';
import { EmpresasService } from 'src/app/services/empresas.service';
import { Empresa } from 'src/app/interfaces/empresa.inter';
import { UiSereviceService } from 'src/app/services/ui-serevice.service';

import { ArticulosService } from 'src/app/services/articulos.service';
import { Cliente } from '../../interfaces/cliente.inter';
import { ClientesService } from '../../services/clientes.service';
import { FacturasService } from '../../services/facturas.service';
import { PdfPrinterService } from '../../services/pdf-printer.service';
import { AlbaranesService } from '../../services/albaranes.service';
import { Linea } from '../../interfaces/albaranes.inter';


@Component({
  selector: 'app-factura',
  templateUrl: './factura.component.html',
  styleUrls: ['./factura.component.scss'],
})
export class FacturaComponent {

  @Input() factura: Factura;
  cliente: Cliente = {};
  empresa: Empresa = {};
  // lineas: Linea[] = [];
  constructor(private modelaController: ModalController,
    private albaranService: AlbaranesService,
    private empresaService: EmpresasService,
    private clientesService: ClientesService,
    private ui: UiSereviceService,
    private alertController: AlertController,
    private articulosService: ArticulosService,
    private facturaService: FacturasService,
    private pdfPrinterService: PdfPrinterService) { }

  async ionViewDidEnter() {
    this.obtenerDatos();
    // this.obtenerArticulo(this.factura.linea);
  }

  cerrar() {
    this.modelaController.dismiss();

  }

  async imprimir(accion?: string) {
    const albaranes = await this.ui.presentConfirm('¿Desea realizar la impresion de los albaranes relacionados?');
    if (albaranes) {
      /*  console.log('Inicia imppresion de albaranes');
        this.factura.linea.forEach(async ln => {
          this.albaranService.pag = 0;
          if (ln.articulo.cod.includes('ALB')) {
            const albaran = await this.albaranService.buscarAlbaran(ln.articulo.cod);
  
  
            let lineas: Linea[] = [];
            albaran.albaran.linea.forEach(async ln => {
              console.log(ln);
              const art = await this.articulosService.getArticulo(ln.articulo);
              if (art.ok) {
                const articulo: Linea = {
                  cod: art.articulo.cod,
                  nombre: art.articulo.nombre,
                  articulo: art.articulo._id,
                  unidades: ln.unidades,
                  total: ln.total
                };
                lineas.push(articulo);
              }
            });
            console.log('Imprime alb ', albaran.albaran.numeroAlbaran);
            this.pdfPrinterService.generateAlbaranPdf(albaran.albaran, this.empresa, this.cliente, albaran.albaran.autonomo, lineas, false, 'p');
          }
        });
  
      }
      */
      this.pdfPrinterService.generateFacturaPdf(this.factura, [], accion, true);

    } else {
      this.pdfPrinterService.generateFacturaPdf(this.factura, [], accion);
    }
    this.modelaController.dismiss();
  }


  envair() {

  }

  async pagar() {
    const alert = await this.alertController.create({
      header: '¡Advertencia!',
      message: '<strong>¿Desea pagar la factura? ' + this.factura.nFactura + '</strong>',
      buttons: [
        {
          text: 'Cancelar',
          role: 'cancel',
        }, {
          text: 'Pagar',
          handler: async () => {
            const resp = await this.facturaService.pagarFactura(this.factura._id);
            if (resp) {
              this.factura.pagado.estado = true;
              this.ui.presentToast('Factura ' + this.factura.nFactura + ' pagada');
              this.factura.pagado.fechaPago = new Date().toString();
            } else {
              this.ui.alertError('Error: No se ha pagado la factura correctamente');
            }
          }
        }
      ]
    });
    await alert.present();
  }

  async obtenerDatos() {
    const respAuto = await this.clientesService.getCliente(this.factura.cliente);
    if (respAuto.ok) {
      this.cliente = respAuto.cliente;
      const respEmp = await this.empresaService.getEmpresa(this.factura.empresa);
      if (respEmp.ok) {
        this.empresa = respEmp.empresa;
      } else {
        this.ui.alertError('Error: no se ha cargado la empresa desde el Backend');
      }
    } else {
      this.ui.alertError('Error: no se ha cargado el autonomo desde el Backend');
    }
  }

  /* obtenerArticulo(lineas: Linea[]) {
    lineas.forEach(async linea => {
      const art = await this.articulosService.getArticulo(linea.articulo);
      if (art.ok){
        const articulo: Linea = {
          cod: art.articulo.cod,
          nombre: art.articulo.nombre,
          articulo: art.articulo._id,
          unidades: linea.unidades,
          total: linea.total
        };
        this.lineas.push(articulo);
      }
    });
  } */
}
