import { Component, OnInit, Input } from '@angular/core';
import { GruService } from '../../services/gru.service';
import { Cargas } from '../../interfaces/gru.inter';
import { UiSereviceService } from '../../services/ui-serevice.service';
import { ModalController, AlertController } from '@ionic/angular';
import { ExcelService } from '../../services/excel.service';
import { PdfPrinterService } from '../../services/pdf-printer.service';
import { Empresa } from '../../interfaces/empresa.inter';

@Component({
  selector: 'app-modal-cargas-gru',
  templateUrl: './modal-cargas-gru.component.html',
  styleUrls: ['./modal-cargas-gru.component.scss'],
})
export class ModalCargasGruComponent implements OnInit {

  @Input() empresa: Empresa;
  cargas: Cargas[] = [];
  buscarFecha: Date;
  filtroActivo: boolean;
  constructor( private gruService: GruService,
               private ui: UiSereviceService,
               private modalController: ModalController,
               private excelService: ExcelService,
               private alertController: AlertController,
               private pdfPrinterService: PdfPrinterService) { }

  async ngOnInit() {
    const resp = await this.gruService.getCargasEmpresa(this.empresa._id);
    if (resp.ok){
      this.cargas = resp.cargas;
    } else {
      this.ui.alertError('Error: Nose han obtenido las cargas GRU desde el backend');
    }
  }

  cerrar(){
    this.modalController.dismiss();
  }
  async buscar(){
    const resp = await this.gruService.getCargasEmpresa(this.empresa._id, this.buscarFecha);
    if (resp.ok){
      this.cargas = resp.cargas;
      this.ui.presentToast('Filtro fechas aplicado');
      this.filtroActivo = true;
    } else {
      this.ui.alertError('Error: No se han obtenido las cargas GRU desde el backend');
    }
  }
  eliminarBussqueda(){
    this.filtroActivo = false;
    this.buscarFecha = undefined;
    this.ngOnInit();
  }
  recargar(){
    this.eliminarBussqueda();
  }
  async eliminarCarga(cargaId: string){

    const alert = await this.alertController.create({
      header: '¡Advertencia!',
      message: '<strong>¿Desea eliminar la carga en GR? Las unidades volveran al almacen de la empresa.</strong>',
      buttons: [
        {
          text: 'Cancelar',
          role: 'cancel',
        }, {
          text: 'Eliminar',
          handler: async () => {
            const elimina: any = await this.gruService.eliminarCarga(cargaId);
            if (elimina.ok){
              this.ui.presentToast('Carga eliminada');
              this.ngOnInit();
            } else {
              this.ui.alertError('Erro: No se ha eliminado la carga, Permisos insuficientes');
            }
          }
        }
      ]
    });

    await alert.present();

  }

  crarExcel(){
    return new Promise<any[]>( resolve => {
      // tslint:disable-next-line: prefer-const
      let cargasXLS: any[] = [];
      this.cargas.forEach(car => {
        // tslint:disable-next-line: prefer-const
        let cargaXls = {
          empresa: car.empresa.nombreComercial,
          articulo: car.articulo.cod,
          tipo: car.tipo,
          cargado: car.carga,
          fecha: new Date(car.fecha),
        };
        cargasXLS.push(cargaXls);
      });
      resolve(cargasXLS);
    });
  }
  async exportarExcel(){
    const cargasXls = await this.crarExcel();
    if (cargasXls && cargasXls.length > 0){
      this.excelService.exportAsExcelFile(cargasXls, 'Cargas');
      this.ui.presentToast('Cargas exportadas');
    } else {
      this.ui.presentToast('Error: No se han exportado las cargas');
    }
  }

  imprimir(accion?: string){
    const cargasPdf: any[] = [];
    let total = 0;
    this.cargas.forEach(c => {
      const f = new Date(c.fecha);
      const fecha = f.getDate() + '-' + (f.getMonth() + 1) + '-' + f.getFullYear();
      const carga = [
        c.empresa.nombreComercial,
        c.articulo.cod,
        c.tipo,
        fecha,
        c.carga
      ];
      total += c.carga;
      cargasPdf.push(carga);
    });
    const cabecera = ['Empresa', 'Articulo', 'Tipo', 'Fecha', 'Unidades'];
    cargasPdf.unshift(cabecera);

   // tslint:disable-next-line: max-line-length
    this.pdfPrinterService.generateTablaCargas(`Gargas GRU: ${this.empresa.gru} - ${this.empresa.nombreComercial}`, cargasPdf, total, accion);

  }

}
