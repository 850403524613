import { Component, OnInit } from '@angular/core';
import { NavController } from '@ionic/angular';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
})
export class MenuComponent implements OnInit {

  menus = [
    {
      nombre: 'Inicio',
      ico: 'home',
      fw: '/inicio'
    },
    {
      nombre: 'Autónomos',
      ico: 'body',
      fw: '/autonomos'
    },
    {
      nombre: 'Clientes',
      ico: 'people-outline',
      fw: '/clientes'
    },
    {
      nombre: 'Artículos',
      ico: 'pricetags-outline',
      fw: '/articulos'
    },
    {
      nombre: 'Albaranes',
      ico: 'file-tray-stacked-outline',
      fw: 'albaranes'
    },
    {
      nombre: 'Facturas',
      ico: 'reader-outline',
      fw: 'facturas'
    },
    {
      nombre: 'Pagos',
      ico: 'wallet-outline',
      fw: 'pagos'
    }
  ];

  constructor(private navController: NavController) { }

  ngOnInit() {}

  onClick(menu){
    this.navController.navigateForward(menu.fw);
  }

}
