import { Component, Input, OnInit } from '@angular/core';
import { Albaran, LineaAlb } from '../../interfaces/albaranes.inter';
import { ModalController } from '@ionic/angular';
import { ModalAutonomosComponent } from '../modal-autonomos/modal-autonomos.component';
import { ModalClientesComponent } from '../modal-clientes/modal-clientes.component';
import { ModalContadorComponent } from '../modal-contador/modal-contador.component';
import { NuevaLineaPage } from '../../pages/albaranes/nueva-linea/nueva-linea.page';
import { FacturasService } from '../../services/facturas.service';
import { UiSereviceService } from '../../services/ui-serevice.service';

@Component({
  selector: 'app-nuevo-albaran',
  templateUrl: './nuevo-albaran.component.html',
  styleUrls: ['./nuevo-albaran.component.scss'],
})
export class NuevoAlbaranComponent implements OnInit {

  @Input()albaran: Albaran = {}
  @Input()edita: boolean = false;
  lineas: LineaAlb[] = [];

  constructor( private modalCtrl: ModalController,
               private ui: UiSereviceService,
               private facturaService: FacturasService) { }

  ngOnInit() {
    if (!this.albaran.linea ||  this.albaran.linea.length === 0){
      this.albaran.linea = [];
    } else {
      this.lineas = this.albaran.linea;
    }
    
  }

  async autonomoSelect(){
    const modal = await this.modalCtrl.create({
      component: ModalAutonomosComponent,
      cssClass: 'autonomo-modal'
    });
    await modal.present();
    const { data } = await modal.onDidDismiss();
    if (data){
      this.albaran.autonomo = data;
    }
  }

  async clienteSelect(){
    const modal = await this.modalCtrl.create({
      component: ModalClientesComponent,
      cssClass: 'autonomo-modal'
    });
    await modal.present();
    const { data } = await modal.onDidDismiss();
    if (data){
      this.albaran.cliente = data;
    }
  }

  async contadorSelect(){
    const modal = await this.modalCtrl.create({
      component: ModalContadorComponent,
      cssClass: 'autonomo-modal'
    });
    await modal.present();
    const { data } = await modal.onDidDismiss();
    if (data){
      this.albaran.contador = data;
    }
  }


  async addLinea(){
    const modal = await this.modalCtrl.create({
      component: NuevaLineaPage,
      cssClass: 'lineAlb-modal'
    });
    await modal.present();
    const { data } = await modal.onDidDismiss();
    if (data){
      this.lineas.push(data);
    }
    this.albaran.linea = this.lineas;
  }

  getTotal(){
    let total = 0;
    this.albaran.linea.forEach(ln => {
      total += ln.total;
    });
    return total;
  }
  del(ln: any) {
    
    this.albaran.linea = this.albaran.linea.filter(f => {
      return f !== ln;
    });
    
  }


  enviar(){
    this.albaran.total = this.getTotal();
    if (!this.edita){
      this.albaran.contador = this.albaran.contador['_id'];
    }
    this.albaran.cliente = this.albaran.cliente._id;
    if (this.albaran.autonomo){
      this.albaran.autonomo = this.albaran.autonomo._id;
    }
    
    const lineaAux = this.albaran.linea;
    this.albaran.linea = [];
    lineaAux.forEach(ln => {
      const linea = {
        articulo: ln.articulo._id,
        unidades: ln.unidades,
        total: ln.total
      };

      this.albaran.linea.push(linea);
    });
    this.modalCtrl.dismiss({ albaran: this.albaran, edita: this.edita });
  }

  cerrar(){
    this.modalCtrl.dismiss();
  }

}
