import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { EmpresaResp, Empresa } from '../interfaces/empresa.inter';
import { FileTransfer, FileUploadOptions, FileTransferObject } from '@ionic-native/file-transfer/ngx';


const URL = environment.urlBackend;

@Injectable({
  providedIn: 'root'
})
export class EmpresasService {

  headers = {
    token: ''
  };

  // tslint:disable-next-line: deprecation
  constructor(private storage: Storage, private http: HttpClient, private fileTransfer: FileTransfer) { }

  private async cargarToken() {
    this.headers.token = await this.storage.get('token');
  }

  getEmpresas() {
    return new Promise<EmpresaResp>(async (resolve) => {
      await this.cargarToken();
      this.http.get(`${URL}/empresas`, { headers: this.headers }).subscribe((resp: EmpresaResp) => {
        resolve(resp);
      });
    });
  }

  getEmpresa(empresaId: any) {
    return new Promise<EmpresaResp>(async (resolve) => {
      await this.cargarToken();
      this.http.get(`${URL}/empresa/${empresaId._id}`, { headers: this.headers }).subscribe((resp: EmpresaResp) => {
        resolve(resp);
      });
    });
  }


  buscarEmpresa(text: string) {
    return new Promise<EmpresaResp>(async resolve => {
      await this.cargarToken();
      if (text.length === 0) {
        resolve(undefined);
      }
      this.http.get(`${URL}/empresa/buscar/${text}`, { headers: this.headers }).subscribe((empresas: EmpresaResp) => {
        resolve(empresas);
      });
    });
  }

  guardarEmpresa(emp: Empresa){
    return new Promise<boolean>(async resolve => {
        await this.cargarToken();
        this.http.post(`${URL}/empresas`, emp, {headers: this.headers}).subscribe((empresaResp: EmpresaResp) => {
          resolve(empresaResp.ok);
        });
    });
  }

  editaEmpresa(emp: Empresa){
    return new Promise<boolean>(async resolve => {
        await this.cargarToken();
        this.http.put(`${URL}/empresas/${emp._id}`, emp, {headers: this.headers}).subscribe((empresaResp: EmpresaResp) => {
          resolve(empresaResp.ok);
        });
    });
  }

  eliminarEmpresa(empId: string){
    return new Promise<boolean>(async resolve => {
        await this.cargarToken();
        this.http.delete(`${URL}/empresas/${empId}`, {headers: this.headers}).subscribe((empresaResp: EmpresaResp) => {
          resolve(empresaResp.ok);
        });
    });
  }

  async postFile(fileToUpload: File, empresaEmail: string) {
      await this.cargarToken();
      const formData: FormData = new FormData();
      formData.append('image', fileToUpload, fileToUpload.name);
      return new Promise((resolve) => {
        this.http.post(`${URL}/upload/${empresaEmail}`, formData, { headers: this.headers }).subscribe((resp: any) => {
        resolve(resp);
      });
    });
  }

}
