import { Component, OnInit } from '@angular/core';
import { IConfig } from '../../interfaces/config.inter';
import { ModalController } from '@ionic/angular';
import { ConfigService } from '../../services/config.service';
import { UiSereviceService } from '../../services/ui-serevice.service';

@Component({
  selector: 'app-modal-config',
  templateUrl: './modal-config.component.html',
  styleUrls: ['./modal-config.component.scss'],
})
export class ModalConfigComponent implements OnInit {

  config: IConfig = {};
  currentConfig: IConfig = {};

  constructor( private modalController: ModalController,
               private configService: ConfigService,
               private ui: UiSereviceService ) { }

  async ngOnInit() {
    const resp = await this.configService.cargarConfig();
    if (resp.ok && resp.config){
      this.currentConfig = resp.config;
      this.config = this.currentConfig;
    } else {
      this.ui.alertError('Error: No existe la configuración de la app, creela en el formulario.');
    }
  }

  async creaConfig(){
    if (!this.currentConfig._id){

      const resp = await this.configService.crearConfig(this.config);
      if (resp.ok){
        this.ui.presentToast('Configuración guardada');
        this.modalController.dismiss();
      }
    } else {
      const resp = await this.configService.actualizaConfig(this.config);
      if (resp.ok){
        this.ui.presentToast('Configuración actualizada');
        this.modalController.dismiss();
      }
    }
  }

  cerrar(){
    this.modalController.dismiss();
  }

}
