import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { ClienteResp, Cliente } from '../interfaces/cliente.inter';

const URL = environment.urlBackend;

@Injectable({
  providedIn: 'root'
})
export class ClientesService {

  pag = 0;

  headers = {
    token: ''
  };

  constructor(private http: HttpClient,
              private storage: Storage) { }

  private async cargarToken() {
    this.headers.token = await this.storage.get('token');
  }

  getClientesActivos(estado: boolean){
    this.pag ++;
    return new Promise<ClienteResp>(async resolve => {
      await this.cargarToken();
      this.http.get(`${URL}/clientes/activos/${estado}?pag=${this.pag}`, {headers: this.headers}).subscribe((clienteResp: ClienteResp) => {
        resolve(clienteResp);
      });
     });
  }

  getCliente(clienteId: any){
    return new Promise<ClienteResp>(async resolve => {
      await this.cargarToken();
      this.http.get(`${URL}/cliente/${clienteId._id}`, {headers: this.headers}).subscribe((resp: ClienteResp) => {
        resolve(resp);
      });
    });
  }

  buscarClientes(text: string, estado?: boolean){
    console.log(estado);
    return new Promise<ClienteResp>(async resolve => {
      await this.cargarToken();
      if (text.length === 0) {
        resolve(undefined);
      }
      this.http.get(`${URL}/clientes/buscar/${text}?estado=${estado}`, { headers: this.headers }).subscribe((clientes: ClienteResp) => {
        resolve(clientes);
      });
    });
  }

  async clientesFiltro(sol: any){
    await this.cargarToken();
    return new Promise<ClienteResp>(async resolve => {
    this.http.put(`${URL}/clientes/buscar`, sol, { headers: this.headers }).subscribe((clientes: ClienteResp) => {
      resolve(clientes);
    });
   });
  }

  nuevoCliente(cliente: Cliente){
    return new Promise<ClienteResp>(async resolve => {
        await this.cargarToken();
        this.http.post(`${URL}/clientes`, cliente, {headers: this.headers}).subscribe((clienteResp: ClienteResp) => {
            resolve(clienteResp);
        });
    });
  }

  editaCliente(cliente: Cliente){
    return new Promise<ClienteResp>(async resolve => {
        await this.cargarToken();
        this.http.put(`${URL}/clientes/${cliente._id}`, cliente, {headers: this.headers}).subscribe((clienteResp: ClienteResp) => {
            resolve(clienteResp);
        });
    });
  }


  cambioEstadoCliente(cliId: string, estado: boolean, fecha?: string){
    return new Promise<ClienteResp>(async resolve => {
        await this.cargarToken();
        this.http.put(`${URL}/clientes/${cliId}`, {estado, fechaBaja: new Date(fecha || '')}, {headers: this.headers}).subscribe((clienteResp: ClienteResp) => {
            resolve(clienteResp);
        });
    });
  }

  eliminaCliente(cliId: string){
    return new Promise<ClienteResp>(async resolve => {
        await this.cargarToken();
        this.http.delete(`${URL}/clientes/${cliId}`, {headers: this.headers}).subscribe((clienteResp: ClienteResp) => {
          resolve(clienteResp);
        });
    });
  }

}
