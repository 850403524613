import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { AlbaranResp, Albaran } from '../interfaces/albaranes.inter';
import { HuecosResp } from '../interfaces/huecos.inter';
import { FacturaResp } from '../interfaces/factura.inter';


const URL = environment.urlBackend;

@Injectable({
  providedIn: 'root'
})
export class AlbaranesService {

  headers = {
    token: ''
  };

  pag = 0;

  constructor(private storage: Storage, private http: HttpClient) { }

  private async cargarToken() {
    this.headers.token = await this.storage.get('token');
  }

  getAlbaranes(){
    this.pag ++ ;
    return new Promise<AlbaranResp>(async resolve => {
      await this.cargarToken();
        // tslint:disable-next-line: max-line-length
      this.http.get(`${URL}/albaranes?pag=${this.pag}`, {headers: this.headers}).subscribe((resp: AlbaranResp) => {
        resolve(resp);
      });
    });
  }
  async guardarAlbaran(albaran: Albaran){
    await this.cargarToken();
    return new Promise<AlbaranResp>(resolve => {
      this.http.post(`${URL}/albaran`, albaran, {headers: this.headers}).subscribe((respAlb: AlbaranResp) => {
        resolve(respAlb);
      });
    });
  }
  async guardarAlbaranSbn(albaran: Albaran){
    await this.cargarToken();
    return new Promise<AlbaranResp>(resolve => {
      this.http.post(`${URL}/albaran?sabana=true`, albaran, {headers: this.headers}).subscribe((respAlb: AlbaranResp) => {
        resolve(respAlb);
      });
    });
  }

  async actualizaAlbaran(alb: Albaran){
    await this.cargarToken();
    return new Promise<AlbaranResp>(resolve => {
      this.http.put(`${URL}/albaran/${alb._id}`, alb, {headers: this.headers}).subscribe( (resp: AlbaranResp) => {
        resolve(resp);
      });
    });
  }

  async getTotalMes(mes: number){
    await this.cargarToken();
    return new Promise<any>(resolve => {
      this.http.get(`${URL}/albaranes/total/mes?mes=${mes}`, {headers: this.headers}).subscribe( (resp) => {
        resolve(resp);
      });
    })
  }

  async buscarAlbaran(text: string){
    this.pag ++ ;
    await this.cargarToken();
    return new Promise<AlbaranResp>(resolve => {
      this.http.get(`${URL}/albaran/numero/${text}?pag=${this.pag}`, {headers: this.headers} ).subscribe((resp: AlbaranResp) => {
        resolve(resp);
      });
    });
  }

  async buscarAlbaranNumero(text: string){
    this.pag ++ ;
    await this.cargarToken();
    return new Promise<AlbaranResp>(resolve => {
      this.http.get(`${URL}/albaran/buscar/${text}?pag=${this.pag}`, {headers: this.headers} ).subscribe((resp: AlbaranResp) => {
        resolve(resp);
      });
    });
  }

  async buscarAutonomo(autonomoId: string){
    await this.cargarToken();
    return new Promise<AlbaranResp>(resolve => {
      this.http.get(`${URL}/albaranes/autonomo/${autonomoId}`, {headers: this.headers}).subscribe((resp: AlbaranResp) => {
        resolve(resp);
      });
    });
  }

  async buscarClientes(cliId: string){
    await this.cargarToken();
    return new Promise<AlbaranResp>(resolve => {
      this.http.get(`${URL}/albaranes/cliente/${cliId}`, {headers: this.headers}).subscribe((resp: AlbaranResp) => {
          resolve(resp);
      });
    });
  }

  async buscarEmpresa(empId: string){
    await this.cargarToken();
    return new Promise<AlbaranResp>(resolve => {
      this.http.get(`${URL}/albaranes/empresa/${empId}`, {headers: this.headers}).subscribe((resp: AlbaranResp) => {
          resolve(resp);
      });
    });
  }

  async albaranesFiltro(sol: any){
    this.pag ++ ;
    await this.cargarToken();
    return new Promise<AlbaranResp>(resolve => {
      this.http.put(`${URL}/albaranes/buscar?pag=${this.pag}`,
      {fecha: sol.fecha, fechaFin: sol.fechaFin, autonomo: sol.autonomo,
        cliente: sol.cliente, empresa: sol.empresa, facturado: sol.facturado},
      {headers: this.headers}).subscribe( (resp: AlbaranResp) => {
        resolve(resp);
      });
    });
  }

  async albaranesFiltroXLS(sol?: any){
    await this.cargarToken();
    return new Promise<AlbaranResp>(resolve => {
      this.http.put(`${URL}/albaranes/xls/buscar`, sol, {headers: this.headers}).subscribe( (resp: AlbaranResp) => {
        resolve(resp);
      });
    });
  }

  async eliminarAlbaran(albaranId: string){
    await this.cargarToken();
    return new Promise<AlbaranResp>(resolve => {
      this.http.delete(`${URL}/albaran/${albaranId}`, {headers: this.headers}).subscribe((resp: AlbaranResp) => {
        resolve(resp);
      });
    });
  }

  async facturarAlbaran(alb: Albaran, emp: boolean, nAfactura: number, fecha: Date, nfactura?: string){
    await this.cargarToken();
    return new Promise<FacturaResp>(resolve => {
      this.http.put(`${URL}/albaran/facturar/${alb._id}`,
      {nAfactura, nfactura, emp, fecha}, {headers: this.headers}).subscribe((resp: FacturaResp) => {
          resolve(resp);
      });
    });
  }

  async facturaLote(albaranes: Albaran[], nAfactura: number, fecha: Date){
    await this.cargarToken();
    return new Promise<any>(resolve => {
      this.http.put(`${URL}/albaranes/facturar-lote`, {albaranes, nAfactura, fecha}, {headers: this.headers}).subscribe((resp: any) => {
        resolve(resp);
      });
    });
  }

  async crearTiket(alb: Albaran){
    await this.cargarToken();
    return new Promise<FacturaResp>(resolve => {
      this.http.put(`${URL}/albaran/tikets/${alb._id}`,
      {}, {headers: this.headers}).subscribe((resp: FacturaResp) => {
       resolve(resp);
      });
    });
  }

  async getTiket(tiketId: string){
    await this.cargarToken();
    return new Promise<any>(resolve => {
      this.http.get(`${URL}/tiket/${tiketId}`,
      {headers: this.headers}).subscribe((resp: any) => {
       resolve(resp);
      });
    });
  }

  async huecosFactura(empId: string){
    await this.cargarToken();
    return new Promise<HuecosResp>(resolve => {
      this.http.get(`${URL}/factura/huecos/${empId}`, {headers: this.headers}).subscribe( (resp: HuecosResp) => {
        resolve(resp);
      });
    });
  }
}
