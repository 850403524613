import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment.prod';
import { HttpClient } from '@angular/common/http';
import { Storage } from '@ionic/storage';

const URL = environment.urlBackend;

@Injectable({
  providedIn: 'root'
})

  export class SabanaService {

  headers = {
    token: ''
  };

  pag = 0;

  constructor(private storage: Storage, private http: HttpClient) { }

  private async cargarToken() {
    this.headers.token = await this.storage.get('token');
  }

  async getSabanas(sol?: any){
    await this.cargarToken();
    return new Promise<any>(resolve => {
      this.http.put(`${URL}/sabanas`, sol, {headers: this.headers}).subscribe(resp => {
        resolve(resp);
      });
    });
  }
  async getSabana(id: string){
    await this.cargarToken();
    return new Promise<any>(resolve => {
      this.http.get(`${URL}/sabanas/${id}`, {headers: this.headers}).subscribe(resp => {
        resolve(resp);
      });
    });
  }

  async getSabanaMes(idCliente: string, mes: any, year: any){
    await this.cargarToken();
    return new Promise<any>(resolve => {
      this.http.get(`${URL}/sabanas/mes/${idCliente}?mes=${mes}&year=${year}`, {headers: this.headers}).subscribe(resp => {
        resolve(resp);
      });      
    });
    
  }

  async setSabana(sabana: any){
    await this.cargarToken();
    return new Promise<any>(resolve => {
      this.http.post(`${URL}/sabanas/`, sabana, {headers: this.headers}).subscribe(resp => {
        resolve(resp);
      });
    });
  }
  async updateSabana(sabana: any){
    await this.cargarToken();
    return new Promise<any>(resolve => {
      this.http.put(`${URL}/sabanas/${sabana._id}`, sabana, {headers: this.headers}).subscribe(resp => {
        resolve(resp);
      });
    });
  }
  async delSabanas(id: string){
    await this.cargarToken();
    return new Promise<any>(resolve => {
      this.http.get(`${URL}/sabanas/${id}`, {headers: this.headers}).subscribe(resp => {
        resolve(resp);
      });
    });
  }

}
