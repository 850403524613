import { Injectable, EventEmitter } from '@angular/core';
import { Storage } from '@ionic/storage';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { PushResp, Push, Autonomo } from '../interfaces/autonomo.inter';
import { NotificacionResp } from '../interfaces/notificacion.inter';
import { Platform } from '@ionic/angular';
import { OneSignal, OSNotification, OSNotificationPayload } from '@ionic-native/onesignal/ngx';

const URL = environment.urlBackend;
const URLOneSignal = 'https://onesignal.com/api/v1/notifications/';
const appId = 'b9c7e054-d446-44b2-953d-38e5e78a2666';
const headerOneSignal = {
  Authorization: 'Basic NWEwYzEyZDAtMjc2Yy00Yjk5LTlhYjEtZDA3ZWM2NTAxMjg2',
  'Content-Type': 'application/json'
};

@Injectable({
  providedIn: 'root'
})
export class PushService {
  pushListenner = new EventEmitter<OSNotificationPayload>();
  mensajes: OSNotificationPayload[] = [];
  headers = {
    token: ''
  };

  pag = 0;

  constructor(private http: HttpClient,
              private oneSignal: OneSignal,
              private storage: Storage,
              public platform: Platform,
    ) {
      this.cargarMensajes();
    }
  tokenId: string;
     modelo: any;

    async getMensajes(){
      return [...await this.cargarMensajes()];
    }

    configuracionInicial(){
      this.oneSignal.startInit('b9c7e054-d446-44b2-953d-38e5e78a2666', '481197214203');

      this.oneSignal.inFocusDisplaying(this.oneSignal.OSInFocusDisplayOption.Notification);

      this.oneSignal.handleNotificationReceived().subscribe(( noti ) => {
        this.notificacionRecivida(noti);
      });

      this.oneSignal.handleNotificationOpened().subscribe(async ( noti ) => {
        await this.notificacionRecivida(noti.notification);
      });

      this.oneSignal.getIds().then( async info => {
        this.tokenId = info.userId;
        if (this.platform.is('android')){
          this.modelo = 'android';
        } else if (this.platform.is('ios')){
          this.modelo = 'ios';
        } else {
          this.modelo = 'otros';
        }
      });

      this.oneSignal.endInit();
    }

    async notificacionRecivida(noti: OSNotification){
      await this.cargarMensajes();

      const payload = noti.payload;
      const existePush = this.mensajes.find(mensaje =>  mensaje.notificationID === payload.notificationID );

      if (existePush){
        return;
      }

      this.mensajes.unshift( payload );
      this.pushListenner.emit ( payload );
      await this.guardarMensaje();

    }

    async guardarMensaje(){
      await this.storage.set('mensajes', this.mensajes);
    }

    async cargarMensajes(){
      this.mensajes = await this.storage.get('mensajes') || [];
      return this.mensajes;
    }

  private async cargarToken() {
    this.headers.token = await this.storage.get('token');
  }

  async crearNotificacion(autonomos: Autonomo[], pushs: string[], mensaje: string, titulo: string, todos: boolean) {
    await this.cargarToken();

    /*const tokenId: string[] = [];
    pushs.forEach(p => {
      tokenId.push(p.tokenId);
    });
*/
    return new Promise<NotificacionResp>(async resolve => {
      const envio: any = {
        app_id: appId,
        data: { userId: 'BioApp' },
        heading: { en: 'Bio Sel Gorup', es: titulo },
        contents: { en: 'Test message', es: mensaje },
        include_player_ids:  pushs,
        included_segments: ['Active Users', 'Inactive Users']
      };

      if (!todos){
        delete envio.included_segments;
      }  else {
        delete envio.include_player_ids;
      }

      this.http.post(`${URLOneSignal}`, JSON.stringify(envio), { headers: headerOneSignal }).subscribe((resp: any) => {
        if (resp.recipients > 0) {

          this.http.post(`${URL}/notificacion`,
            { autonomos, titulo, mensaje, todos }, { headers: this.headers }).subscribe((respMen: NotificacionResp) => {
              if (respMen.ok) {
                resolve(respMen);
              }
            });
        } else {
          resolve(undefined);
        }
      });
    });
  }

  async obtenerAutonomos(){
      await this.cargarToken();
      return new Promise<PushResp>(resolve => {
          this.http.get(`${URL}/push-autonomo`, { headers: this.headers }).subscribe((resp: PushResp) => {
            resolve(resp);
        });
      });
  }

  async getNotificaciones(){
    await this.cargarToken();
    return new Promise<NotificacionResp>(resolv => {
      this.http.get(`${URL}/notificacion`, {headers: this.headers}).subscribe((resp: NotificacionResp) => {
        resolv(resp);
      });
    });
  }
}
