import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { FacturaResp } from '../interfaces/factura.inter';
import { PagosResp } from '../interfaces/pagos.inter';
import { Cliente, ClienteResp } from '../interfaces/cliente.inter';
import { Empresa } from '../interfaces/empresa.inter';

const URL = environment.urlBackend;

@Injectable({
  providedIn: 'root'
})
export class FacturasService {
  headers = {
    token: ''
  };

  pag = 0;

  constructor(private storage: Storage, private http: HttpClient) { }

  private async cargarToken() {
    this.headers.token = await this.storage.get('token');
  }

  async getFacturas(){
    this.pag ++;
    await this.cargarToken();
    return new Promise<FacturaResp>(resolve => {
      this.http.get(`${URL}/factura?pag=${this.pag}`, {headers: this.headers}).subscribe((resp: FacturaResp) => {
        resolve(resp);
      });
    });
  }
  async getFactura(facturaId: string){
    this.pag ++;
    await this.cargarToken();
    return new Promise<FacturaResp>(resolve => {
      this.http.get(`${URL}/factura/ver/${facturaId}`, {headers: this.headers}).subscribe((resp: FacturaResp) => {
        resolve(resp);
      });
    });
  }

  async buscarFactura(text: string){
    await this.cargarToken();
    return new Promise<FacturaResp>(resolve => {
      this.http.get(`${URL}/factura/buscar/${text}`, {headers: this.headers}).subscribe((resp: FacturaResp) => {
        resolve(resp);
      });
    });
  }
  
  async facturaFiltro(sol: any){
    this.pag ++ ;
    await this.cargarToken();
    return new Promise<FacturaResp>(resolve => {
      this.http.put(`${URL}/factura/buscar?pag=${this.pag}`,
      {fechaAlta: sol.fecha, fechaFin: sol.fechaFin, cliente: sol.cliente,
       empresa: sol.empresa, pagado: sol.pagado},
      {headers: this.headers}).subscribe( (resp: FacturaResp) => {
        resolve(resp);
      });
    });
  }

  async getFacturasXLS(sol?: any){
    await this.cargarToken();
    return new Promise<FacturaResp>(resolve => {
      this.http.put(`${URL}/factura/xls/buscar`, {fechaAlta: sol.fecha, fechaFin: sol.fechaFin, autonomo: sol.autonomo,
        empresa: sol.empresa, pagado: sol.pagado}, {headers: this.headers}).subscribe((resp: FacturaResp) => {
        resolve(resp);
      });
    });
  }

  async pagarFactura(nFactura: string){
    await this.cargarToken();
    return new Promise<PagosResp>(resolve => {
      this.http.put(`${URL}/factura/pago/${nFactura}`, {}, {headers: this.headers}).subscribe((resp: PagosResp) => {
        if (resp.ok){
          this.http.get(`${URL}/pagos/${resp.pago._id}`, {headers: this.headers}).subscribe((respPago: PagosResp) => {
            resolve(respPago);
          });
        }
      });
    });
  }

  async eliminarFactura(facId: string){
    await this.cargarToken();
    return new Promise<boolean>(resolve => {
      this.http.delete(`${URL}/factura/${facId}`, {headers: this.headers}).subscribe((resp: FacturaResp) => {
         resolve(resp.ok);
      });
    });
  }

  async obtenerTotalFacturaCliente(cliente: Cliente){
      await this.cargarToken();
      return new Promise<ClienteResp>(resolve => {
        this.http.get(`${URL}/factura/cliente/${cliente._id}`, {headers: this.headers} ).subscribe((resp: ClienteResp) => {
          resolve(resp);
        });
      });
  }

  async calcularLimite(cliente: string, total: number){
    await this.cargarToken();
    return new Promise<any>(resolve => {
      this.http.put(`${URL}/factura/calcula-limite`, {cliente, total}, {headers: this.headers}).subscribe((resp: any) => {
        resolve(resp);
      });
    });
  }
}
