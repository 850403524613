import { Component } from '@angular/core';
import { Empresa } from '../../../interfaces/empresa.inter';
import { ModalController } from '@ionic/angular';
import { EmpresasService } from '../../../services/empresas.service';
import { UiSereviceService } from '../../../services/ui-serevice.service';

declare var window: any;

@Component({
  selector: 'app-form-empresa',
  templateUrl: './form-empresa.page.html',
  styleUrls: ['./form-empresa.page.scss'],
})
export class FormEmpresaPage {


  empresa: Empresa = {}
  image: any;
  logoUbload: File = null;
  files: File[] = [];
  processing: boolean;
  uploadImage: string;

  constructor(private modalController: ModalController,
              private ui: UiSereviceService, 
              private empresaService: EmpresasService) { }

  ionViewDidEnter() {
    this.cargarEmpresa();
  }

  async cargarEmpresa(){
    const emp = await this.empresaService.getEmpresas();
    if(!emp.ok){
      this.ui.alertError('Error al obtener la empresa');
      return;
    }
    if (emp.empresas.length > 0) {
      this.empresa = emp.empresas[0];
    }
  }

  async formEmpresa(){
    let save;
    if (this.empresa._id){
      save = await this.empresaService.editaEmpresa(this.empresa);
    } else {
      save = await this.empresaService.guardarEmpresa(this.empresa);
    }
    if (!save){
      this.ui.alertError('Error al almacenar los datos de la empresa');
      return;
    }
    this.modalController.dismiss();
  }
  cerrar(){
    this.modalController.dismiss();
  }

  async presentActionSheet(fileLoader: any){
    fileLoader.click();
    const that = this;
    fileLoader.onchange = () => {
      const file = fileLoader.files[0];
      this.files.push(file);
      const reader = new FileReader();

      reader.addEventListener('load', () => {
        that.processing = true;

      }, false);
      if (file) {
        reader.readAsDataURL(file);
        reader.onload = () => {
          this.empresa.logo = String(reader.result);
        };
      }
    };
    }
  }


