import { Component, OnInit } from '@angular/core';
import { UiSereviceService } from '../../services/ui-serevice.service';
import { ModalController } from '@ionic/angular';
import { ContadoresService } from '../../services/contadores.service';

@Component({
  selector: 'app-modal-contador',
  templateUrl: './modal-contador.component.html',
  styleUrls: ['./modal-contador.component.scss'],
})
export class ModalContadorComponent implements OnInit {

  contadores: any = [];

  constructor( private ui: UiSereviceService,
               private modalCtrl: ModalController,
               private contadorService: ContadoresService) { }

  async ngOnInit() {
    const conts = await this.contadorService.getContadores();
    if (!conts.ok){
      this.ui.alertError('Error al obtener los contadores');
      return;
    }
    this.contadores = conts.contadores;
  }

  select(contador: any){
    this.modalCtrl.dismiss(contador);
  }
  cerrar(){
    this.modalCtrl.dismiss();
  }

  async buscar(event: any){
    const cont = await this.contadorService.buscarContadores(event.detail.value);
    if (!cont.ok){
      this.ui.alertError('Error al realizar la busqueda del contador');
      return
    }
    this.contadores = cont.contadores;
  }

}
