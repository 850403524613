import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { AlmacenResp } from '../interfaces/almacen.inter';

const URL = environment.urlBackend;

@Injectable({
  providedIn: 'root'
})
export class AlmacenService {

  headers = {
    token: ''
  };

  constructor(private storage: Storage, private http: HttpClient) { }

  private async cargarToken() {
    this.headers.token = await this.storage.get('token');
  }

  async anadirAlmacen(empId: string, artId: string, unidades: number){
    await this.cargarToken();
    return new Promise(resolve => {
      this.http.post(`${URL}/almacen`, {articulo: artId, empresa: empId, unidades}, {headers: this.headers}).subscribe(resp => {
        resolve(resp);
      });
    });
  }

  async getAlmacen(empId: string){
    await this.cargarToken();
    return new Promise<AlmacenResp>(resolve => {
      this.http.get(`${URL}/almacen/${empId}`, {headers: this.headers}).subscribe((resp: AlmacenResp) => {
        resolve(resp);
      });
    });
  }

  async descontarAlmacen(empId: string, artId: string, unidades: number){
    await this.cargarToken();
    return new Promise(resolve => {
      this.http.put(`${URL}/almacen`, {articulo: artId, empresa: empId, unidades}, {headers: this.headers}).subscribe( resp => {
        resolve(resp);
      });
    });
  }
}
