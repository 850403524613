import { Component, OnInit, Input } from '@angular/core';
import { LineaAlb, Linea } from '../../../interfaces/albaranes.inter';
import { ModalController } from '@ionic/angular';
import { Articulo } from 'src/app/interfaces/articulo.inter';
import { ArticulosService } from '../../../services/articulos.service';
import { UiSereviceService } from '../../../services/ui-serevice.service';

@Component({
  selector: 'app-nueva-linea',
  templateUrl: './nueva-linea.page.html',
  styleUrls: ['./nueva-linea.page.scss'],
})
export class NuevaLineaPage implements OnInit {

  titulo: string;
  articulo: Articulo = {};
  articulos: Articulo[] = [];
  txtBuscar: string;
  linea: Linea = {};
  total = 0;
  unidades: number;
  @Input() edita: boolean;
  @Input()sabana: boolean = false;

  constructor(private modalcontroller: ModalController,
              private articulosService: ArticulosService,
              private ui: UiSereviceService) { }

  async ngOnInit() {
    if (this.edita){
      this.titulo = 'Edición de línea';
    } else {
      this.titulo = 'Nueva línea';
    }
    const resp = await this.articulosService.getArticulos();
    if (resp.ok){
      this.articulos = resp.articulos;
    } else {
      this.ui.alertError('Error: No se han cargado los articulos desde el Backend');
    }
  }

  eventKey(event){
    if (event.keyCode === 13){
        this.guardar();
    }
  }

  selectArt(art: Articulo){
    this.articulo = art;
  }

  calculaTotal(){
    this.total = Number((this.unidades * this.articulo.precioUnidad).toFixed(3));
  }

  bucarArticulo(event){
    this.txtBuscar = event.detail.value;
  }

  cerrar(){
    this.modalcontroller.dismiss();
  }

  seleccionar(){
    this.modalcontroller.dismiss(this.articulo);
  }

  guardar(){
    this.calculaTotal();
    this.linea.articulo = {};
    this.linea.articulo._id = this.articulo._id;
    this.linea.articulo.nombre = this.articulo.nombre,
    this.linea.articulo.cod = this.articulo.cod;
    this.linea.unidades = this.unidades;
    this.linea.total = this.total;
    this.unidades = undefined;

    this.modalcontroller.dismiss(this.linea);

  }
}
