import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';

const URL = environment.urlBackend;

@Injectable({
  providedIn: 'root'
})
export class ContadoresService {

  headers = {
    token: ''
  };

  constructor(private http: HttpClient,
              private storage: Storage) { }

  private async cargarToken() {
    this.headers.token = await this.storage.get('token');
  }

  async getContadores(){
    await this.cargarToken();
    return new Promise<any>(resolve => {
      this.http.get(`${URL}/alb-count`, {headers: this.headers}).subscribe(resp => {
        resolve(resp);
      });
    });
  }

  async buscarContadores(text: string){
    await this.cargarToken();
    return new Promise<any>(resolve => {
      if (text || text.length > 0){
        this.http.get(`${URL}/alb-count/buscar/${text}`, {headers: this.headers}).subscribe(resp => {
          resolve(resp);
        });
      } else {
        this.http.get(`${URL}/alb-count`, {headers: this.headers}).subscribe(resp => {
          resolve(resp);
        });

      }
    });
  }
  async getContador(contadorId: string){
    await this.cargarToken();
    return new Promise<any>(resolve => {
      this.http.get(`${URL}/alb-count/${contadorId}`, {headers: this.headers}).subscribe(resp => {
        resolve(resp);
      });
    });
  }
  async setContador(contador: any){
    await this.cargarToken();
    return new Promise<any>(resolve => {
      this.http.post(`${URL}/alb-count`, contador, {headers: this.headers}).subscribe(resp => {
        resolve(resp);
      });
    });
  }
  async updateContadores(contador: any){
    await this.cargarToken();
    return new Promise<any>(resolve => {
      this.http.put(`${URL}/alb-count/${contador._id}`, contador, {headers: this.headers}).subscribe(resp => {
        resolve(resp);
      });
    });
  }
  async delContadores(contadorId: string){
    await this.cargarToken();
    return new Promise<any>(resolve => {
      this.http.delete(`${URL}/alb-count/${contadorId}`, {headers: this.headers}).subscribe(resp => {
        resolve(resp);
      });
    });
  }
}
