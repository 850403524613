import { Component, OnInit, Input } from '@angular/core';
import { LoginService } from '../../services/login.service';
import { ActivatedRoute } from '@angular/router';
import { NavController, ModalController } from '@ionic/angular';
import { UiSereviceService } from 'src/app/services/ui-serevice.service';
import { AlmacenService } from 'src/app/services/almacen.service';
import { Empresa } from '../../interfaces/empresa.inter';
import { EmpresasService } from '../../services/empresas.service';


@Component({
  selector: 'app-modal-almacen',
  templateUrl: './modal-almacen.component.html',
  styleUrls: ['./modal-almacen.component.scss'],
})
export class ModalAlmacenComponent implements OnInit {

  almacen: any[] = [];
  @Input() empresa: string;
  emp: Empresa = {};

  constructor(private empresasService: EmpresasService,
              private ui: UiSereviceService,
              private almacenService: AlmacenService,
              private modalController: ModalController) { }

async ngOnInit() {

  const resp = await this.almacenService.getAlmacen(this.empresa);
  if (resp.ok){
    this.almacen = resp.articulos;
  }
  this.obtenerEmpresa();
  }

  async obtenerEmpresa(){
    const em = {
      _id: this.empresa
    };

    const respEmp = await this.empresasService.getEmpresa(em);
    if (respEmp.ok){
      this.emp = respEmp.empresa;
    } else {
      this.ui.alertError('Error, No se ha obtenido la empresa desde el backend');
    }
  }

  cerrar(){
    this.modalController.dismiss();
  }

  gru(alm: any){
    this.modalController.dismiss(alm);
  }

}

