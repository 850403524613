import { Component, Input, OnInit } from '@angular/core';
import { Notificacion } from '../../interfaces/notificacion.inter';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-modal-notificacion',
  templateUrl: './modal-notificacion.component.html',
  styleUrls: ['./modal-notificacion.component.scss'],
})
export class ModalNotificacionComponent implements OnInit {


  @Input()notificacion: Notificacion;

  constructor( private modalCtrl: ModalController) { }

  ngOnInit() {}

  cerrar(){
    this.modalCtrl.dismiss();
  }

}
