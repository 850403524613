import { Injectable } from '@angular/core';
import * as pdfFonts from 'pdfmake/build/vfs_fonts';
import { PdfMakeWrapper, Txt, Table, Canvas, Rect, Stack, Img, Line } from 'pdfmake-wrapper';
import { Pago } from '../interfaces/pagos.inter';
import { Factura } from '../interfaces/factura.inter';
import { Linea, Albaran } from '../interfaces/albaranes.inter';
import { ArticulosService } from './articulos.service';
import { Empresa } from '../interfaces/empresa.inter';
import { Cliente } from '../interfaces/cliente.inter';
import { Autonomo } from '../interfaces/autonomo.inter';
import { environment } from '../../environments/environment.prod';
import { EmailService } from './email.service';
import { UiSereviceService } from './ui-serevice.service';
import { EmpresasService } from './empresas.service';
import { AlbaranesService } from './albaranes.service';
import { Sabana, Item } from '../interfaces/sabana.inter';

const URL = environment.urlBackend;


PdfMakeWrapper.setFonts(pdfFonts);


@Injectable({
  providedIn: 'root'
})
export class PdfPrinterService {

  constructor(private articulosService: ArticulosService,
    private emailService: EmailService,
    private albaranService: AlbaranesService,
    private ui: UiSereviceService,
    private empresaSErvice: EmpresasService) { }

  generatePagoPdf(titulo: string, pago: Pago, accion?: string) {
    const pdf = new PdfMakeWrapper();

    const fecha = new Date(pago.fecha);
    const fechaPago = fecha.getDate() + '-' + (fecha.getMonth() + 1) + '-' + fecha.getFullYear();

    pdf.info({
      title: 'pago factura: ' + pago.factura.nFactura,
      author: 'bioApp',
    });
    pdf.pageSize('A5');

    pdf.add(
      new Txt(titulo + ' ' + pago.empresa.nombreFiscal).alignment('center').italics().bold().fontSize(15).end
    );
    pdf.add(
      pdf.ln(1)
    );
    pdf.add(new Txt(
      `Pago realizado  a la empresa ${pago.cliente.nombre} por la cantidad de ${pago.cantidad}€, de los cuales ${pago.iva}€ corresponde al impuesto (I.V.A).`).alignment('justify').fontSize(10).end);

    pdf.add(
      pdf.ln(1)
    );

    pdf.add(new Txt(`Pago en concepto de auto - factura emitida con el número ${pago.factura.nFactura} a fecha de ${fechaPago}`).alignment('justify').fontSize(10).end);

    pdf.add(
      pdf.ln(1)
    );

    pdf.add(new Txt('Resumen:').end);
    pdf.add(new Txt(`Fecha: ${fechaPago}`).bold().end);
    pdf.add(new Txt(`Factura: ${pago.factura.nFactura}`).bold().end);
    pdf.add(new Txt(`Importe base: ${pago.cantidad - pago.iva}€`).bold().end);
    pdf.add(new Txt(`Iva: 21%: ${pago.iva}€`).bold().end);
    pdf.add(new Txt(`Total: ${pago.cantidad}€`).bold().end);

    pdf.add(
      pdf.ln(2)
    );
    pdf.add(new Txt(`Fdo: ${pago.empresa.nombreFiscal} _______________________`).alignment('center').fontSize(9).end);

    switch (accion) {
      case 'p':
        pdf.create().print();
        break;
      case 'o':
        pdf.create().open();
        break;
      default:
        pdf.create().open();
        break;
    }
  }

  async generateFacturaPdf(factura: Factura, lineas: Linea[], accion?: string, albaranesAgrupados?: boolean) {
    const fecha = new Date(factura.fechaAlta);
    const modelo = await this.empresaSErvice.getEmpresa(factura.empresa);
    if (!modelo.empresa.modeloFact || modelo.empresa.modeloFact < 1 || modelo.empresa.modeloFact > 3) {
      this.ui.alertError('No se ha localizado el modelo de impresión de facturas para la empresa');
      return;
    }

    const pdf = new PdfMakeWrapper();

    pdf.info({
      title: 'factura ' + factura.aFactura,
      author: 'bioApp',
    });

    let pagado: string;
    if (factura.pagado.estado) {
      pagado = 'Pagado';
      pdf.watermark(new Txt(pagado).color('red').fontSize(50).end);
    }

    switch (modelo.empresa.modeloFact) {
      case 1: // Estandar 1
        pdf.add(new Txt('AUTOFACTURA').fontSize(20).bold().alignment('center').color('#6bc511').end);
        pdf.add(
          pdf.ln(3)
        );

        // Datos de empresa que factura
        pdf.add(new Stack([
          factura.cliente.nombreFiscal,
          'CIF: ' + factura.cliente.cif,
          'Dirección: ' + factura.cliente.direccion,
          'Localidad: ' + factura.cliente.localidad,
          'Provncia: ' + factura.cliente.provincia,
          'C.P.: ' + factura.cliente.cp,
          // 'Teléfono: ' + factura.cliente.telefono,
          // 'E-Mail: ' + factura.cliente.email,
        ]).fontSize(10).end);

        pdf.add(
          pdf.ln(2)
        );

        pdf.add(new Txt('AutoFactura número: ' + factura.aFactura).fontSize(10).alignment('left').bold().end)
        pdf.add(new Txt(fecha.getDate() + ' del ' + (fecha.getMonth() + 1) + ' de ' + fecha.getFullYear())
          .fontSize(8).alignment('left').bold().end);

        pdf.add(
          new Canvas([
            new Rect([5, 5], [250, 115]).lineColor('#6bc511').end
          ]).absolutePosition(300, 100).end
        );

        // Datos de empresa
        pdf.add(new Stack([
          'Datos de Cliente',
          ' ',
          factura.empresa.nombreFiscal,
          'CIF: ' + factura.empresa.cif,
          'Dirección: ' + factura.empresa.direccion,
          'Localidad: ' + factura.empresa.localidad,
          'Provncia: ' + factura.empresa.provincia,
          'C.P.: ' + factura.empresa.cp,
          'Teléfono: ' + factura.empresa.telefono,
          'E-Mail: ' + factura.empresa.email,
        ]).fontSize(10).absolutePosition(310, 90).end);

        // Cuerpo de Factura
        pdf.add(
          pdf.ln(1)
        );
        pdf.add(new Table([
          ['COD.', 'NOMBRE', 'UNIDADES (Kg)', 'TOTAL']
        ]).widths([100, 260, 60, 60]).color('#6bc511').fontSize(10).alignment('center').bold().end);

        factura.linea.forEach(l => {
          let total = ''
          if (l.total > 0) {
            total = l.total.toFixed(2) + '€';
          }
          const ln = [
            l.articulo.cod || '',
            l.articulo.nombre || '',
            (l.unidades * 0.90).toFixed(2) || '',
            total
          ];
          pdf.add(new Table([
            ln
          ]).widths([100, 260, 60, 60]).fontSize(9).margin(5).alignment('center').layout('noBorders').end);
        });

        pdf.add(
          pdf.ln(3)
        );

        // Pie de factura
        pdf.add(new Canvas([
          new Line([0, 325], [518, 325]).lineWidth(1).end
        ]).absolutePosition(40, 275).end);

        pdf.add(new Table([
          ['Descuento', '0.00 €'],
          ['Base imponible', `${(factura.total - factura.iva).toFixed(2)} €`],
          ['Impuesto 21%', `${factura.iva.toFixed(2)} €`]
        ]).widths([100, 100])
          .layout('noBorders')
          .absolutePosition(350, 600)
          .fontSize(10).alignment('right').bold().end);

        pdf.add(new Canvas([
          new Line([300, 60], [518, 60]).lineWidth(1).end
        ]).absolutePosition(40, 590).end);

        pdf.add(new Txt(factura.total.toFixed(2) + ' €')
          .color('#6bc511')
          .fontSize(15)
          .alignment('right')
          .absolutePosition(0, 650)
          .end);

        pdf.add(new Canvas([
          new Line([0, 10], [518, 10]).lineWidth(2).end
        ]).absolutePosition(40, 665).color('#6bc511').end);

        pdf.add(
          pdf.ln(1)
        );

        pdf.add(new Txt('FOMRA DE PAGO')
          .color('#6bc511')
          .fontSize(10)
          .bold()
          .alignment('left')
          .absolutePosition(40, 680).end);

        pdf.add(new Txt('CONTADO')
          .fontSize(8)
          .bold()
          .alignment('left')
          .absolutePosition(40, 691).end);

        pdf.add(new Txt('INFORMACIÓN ADICIONAL')
          .color('#6bc511')
          .fontSize(10)
          .bold()
          .alignment('right')
          .absolutePosition(0, 680).end);


          if (albaranesAgrupados){
            pdf.add(new Txt('NINGUNA')
              .fontSize(8)
              .bold()
              .alignment('right')
              .absolutePosition(0, 691).pageBreak('after').end);
    
          } else {
            pdf.add(new Txt('NINGUNA')
              .fontSize(8)
              .bold()
              .alignment('right')
              .absolutePosition(0, 691).end);
          }   
            break;

      case 2: // Estandar 2
        pdf.add(new Txt('AutoFactura: ' + factura.aFactura).fontSize(15).bold().alignment('center').color('#547047').end);
        pdf.add(new Txt(fecha.getDate() + ' del ' + (fecha.getMonth() + 1) + ' de ' + fecha.getFullYear())
          .fontSize(9).bold().alignment('center').color('#547047').end);

        pdf.add(
          pdf.ln(3)
        );

        // Datos de empresa que factura
        pdf.add(new Stack([
          factura.cliente.nombreFiscal,
          'CIF: ' + factura.cliente.cif,
          'Dirección: ' + factura.cliente.direccion,
          'Localidad: ' + factura.cliente.localidad,
          'Provncia: ' + factura.cliente.provincia,
          'C.P.: ' + factura.cliente.cp,
          //'Teléfono: ' + factura.cliente.telefono,
          //'E-Mail: ' + factura.cliente.email,
        ]).fontSize(10).end);

        pdf.add(
          new Canvas([
            new Rect([5, 5], [250, 115]).lineColor('#547047').end
          ]).absolutePosition(300, 100).end
        );

        // Datos de empresa
        pdf.add(new Stack([
          'Datos de Cliente',
          ' ',
          factura.empresa.nombreFiscal,
          'CIF: ' + factura.empresa.cif,
          'Dirección: ' + factura.empresa.direccion,
          'Localidad: ' + factura.empresa.localidad,
          'Provncia: ' + factura.empresa.provincia,
          'C.P.: ' + factura.empresa.cp,
          'Teléfono: ' + factura.empresa.telefono,
          'E-Mail: ' + factura.empresa.email,
        ]).fontSize(10).absolutePosition(310, 90).end);

        // Cuerpo de Factura
        pdf.add(
          pdf.ln(3)
        );
        pdf.add(new Table([
          ['COD.', 'NOMBRE', 'UNIDADES (Kg)', 'TOTAL']
        ]).widths([100, 260, 60, 60]).color('#547047').fontSize(10).alignment('center').bold().end);

        factura.linea.forEach(l => {
          let total = ''
          if (l.total > 0) {
            total = l.total + '€';
          }
          const ln = [
            l.articulo.cod || '',
            l.articulo.nombre || '',
            l.unidades * 0.90 || '',
            total
          ];
          pdf.add(new Table([
            ln
          ]).widths([100, 260, 60, 60]).fontSize(9).margin(5).alignment('center').layout('noBorders').end);
        });

        pdf.add(
          pdf.ln(3)
        );

        // Pie de factura
        pdf.add(new Canvas([
          new Line([0, 325], [518, 325]).lineWidth(1).end
        ]).absolutePosition(40, 275).end);

        pdf.add(new Table([
          ['Descuento', '0.00 €'],
          ['Base imponible', `${(factura.total - factura.iva).toFixed(2)} €`],
          ['Impuesto 21%', `${factura.iva.toFixed(2)} €`]
        ]).widths([100, 100])
          .layout('noBorders')
          .absolutePosition(350, 600)
          .fontSize(10).alignment('right').bold().end);

        pdf.add(new Canvas([
          new Line([300, 60], [518, 60]).lineWidth(1).end
        ]).absolutePosition(40, 590).end);

        pdf.add(new Txt(factura.total.toFixed(2) + ' €')
          .color('#547047')
          .fontSize(15)
          .alignment('right')
          .absolutePosition(0, 650)
          .end);

        pdf.add(new Canvas([
          new Line([0, 10], [518, 10]).lineWidth(2).end
        ]).absolutePosition(40, 665).color('#547047').end);

        pdf.add(
          pdf.ln(1)
        );

        pdf.add(new Txt('FOMRA DE PAGO')
          .color('#547047')
          .fontSize(10)
          .bold()
          .alignment('left')
          .absolutePosition(40, 680).end);

        pdf.add(new Txt('CONTADO')
          .fontSize(8)
          .bold()
          .alignment('left')
          .absolutePosition(40, 691).end);

        pdf.add(new Txt('INFORMACIÓN ADICIONAL')
          .color('#547047')
          .fontSize(10)
          .bold()
          .alignment('right')
          .absolutePosition(0, 680).end);

          if (albaranesAgrupados){
            pdf.add(new Txt('NINGUNA')
              .fontSize(8)
              .bold()
              .alignment('right')
              .absolutePosition(0, 691).pageBreak('after').end);
    
          } else {
            pdf.add(new Txt('NINGUNA')
              .fontSize(8)
              .bold()
              .alignment('right')
              .absolutePosition(0, 691).end);
          }   
            break;
       
      case 3: // Hispalis    
        pdf.add(new Txt('AUTOFACTURA')
          .fontSize(15)
          .bold()
          .alignment('left').end)

        pdf.add(new Txt(factura.aFactura)
          .fontSize(14)
          .bold()
          .alignment('right')
          .color('#ff9531')
          .relativePosition(0, -15).end);

        pdf.add(new Canvas([
          new Line([0, 8], [515, 8]).lineWidth(3).end
        ]).end);

        pdf.add(new Txt(fecha.getDate() + ' del ' + (fecha.getMonth() + 1) + ' de ' + fecha.getFullYear())
          .fontSize(8)
          .relativePosition(0, 3)
          .alignment('left').end);

        pdf.add(new Txt(factura.total.toFixed(2) + ' €')
          .color('#ff9531')
          .fontSize(25)
          .alignment('right')
          .bold()
          .end);

        pdf.add(new Canvas([
          new Line([0, 0], [515, 0]).lineWidth(1).end
        ]).end);


        // Datos de empresa que factura
        pdf.add(new Txt('EMISOR')
          .bold()
          .fontSize(15)
          .width(5).end);

        pdf.add(new Stack([
          factura.cliente.nombreFiscal,
          'CIF: ' + factura.cliente.cif,
          'Dirección: ' + factura.cliente.direccion,
          'Localidad: ' + factura.cliente.localidad,
          'Provncia: ' + factura.cliente.provincia,
          'C.P.: ' + factura.cliente.cp,
         // 'Teléfono: ' + factura.cliente.telefono,
         // 'E-Mail: ' + factura.cliente.email,
        ]).fontSize(10).end);

        // Datos del cliente

        pdf.add(new Txt('CLIENTE')
          .bold()
          .fontSize(15)
          .alignment('right')
          .relativePosition(0, -111).end);

        pdf.add(new Stack([
          factura.empresa.nombreFiscal,
          'CIF: ' + factura.empresa.cif,
          'Dirección: ' + factura.empresa.direccion,
          'Localidad: ' + factura.empresa.localidad,
          'Provncia: ' + factura.empresa.provincia,
          'C.P.: ' + factura.empresa.cp,
          'Teléfono: ' + factura.empresa.telefono,
          'E-Mail: ' + factura.empresa.email,
        ]).fontSize(10)
          .alignment('right')
          .relativePosition(0, -95).end);

        pdf.add(new Canvas([
          new Line([0, 8], [515, 8]).lineWidth(3).end
        ]).end);


        // Cuerpo de Factura
        pdf.add(
          pdf.ln(1)
        );
        pdf.add(new Table([
          ['CANTIDAD', 'CONCEPTO', 'PRECIO UND.', 'TOTAL LÍNEA']
        ]).widths([60, 280, 40, 100])
          .layout('lightHorizontalLines')
          .fontSize(10).alignment('center').bold().end);


        factura.linea.forEach(l => {
          if (!l.articulo.precioUnidad || l.articulo.precioUnidad === 0) {
            l.articulo.precioUnidad = ''
          } else {
            l.articulo.precioUnidad = (l.total / l.unidades) + ' €';
          }
          let total = '';
          if (l.total > 0) {
            total = l.total + '€'
          }
          const ln = [
            (l.unidades * 0.90 || ''),
            (l.articulo.cod + ' - ' + l.articulo.nombre) || '',
            l.articulo.precioUnidad,
            total
          ];
          pdf.add(new Table([
            ln
          ]).widths([60, 280, 40, 100]).fontSize(9).margin(5).alignment('center').layout('noBorders').end);
        });

        // PIE DE FACTURA
        pdf.add(new Canvas([
          new Line([0, 325], [518, 325]).lineWidth(1).end
        ]).absolutePosition(40, 275).end);

        pdf.add(new Table([
          ['Descuento', '0.00 €'],
          ['Base imponible', `${(factura.total - factura.iva).toFixed(2)} €`],
          ['Impuesto 21%', `${factura.iva.toFixed(2)} €`]
        ]).widths([100, 100])
          .layout('noBorders')
          .absolutePosition(350, 600)
          .fontSize(10).alignment('right').bold().end);

        pdf.add(new Canvas([
          new Line([300, 60], [518, 60]).lineWidth(1).end
        ]).absolutePosition(40, 590).end);

        pdf.add(new Txt(factura.total.toFixed(2) + ' €')
          .color('#ff9531')
          .fontSize(15)
          .alignment('right')
          .absolutePosition(0, 650)
          .end);

        pdf.add(new Canvas([
          new Line([0, 10], [518, 10]).lineWidth(3).end
        ]).absolutePosition(40, 665).end);

        pdf.add(
          pdf.ln(1)
        );

        pdf.add(new Txt('FOMRA DE PAGO')
          .color('#ff9531')
          .fontSize(10)
          .bold()
          .alignment('left')
          .absolutePosition(40, 680).end);

        pdf.add(new Txt('CONTADO')
          .fontSize(8)
          .bold()
          .alignment('left')
          .absolutePosition(40, 691).end);

        pdf.add(new Txt('INFORMACIÓN ADICIONAL')
          .color('#ff9531')
          .fontSize(10)
          .bold()
          .alignment('right')
          .absolutePosition(0, 680).end);

      if (albaranesAgrupados){
        pdf.add(new Txt('NINGUNA')
          .fontSize(8)
          .bold()
          .alignment('right')
          .absolutePosition(0, 691).pageBreak('after').end);

      } else {
        pdf.add(new Txt('NINGUNA')
          .fontSize(8)
          .bold()
          .alignment('right')
          .absolutePosition(0, 691).end);
      }   
        break;
    }

    if (albaranesAgrupados) {
      
        factura.linea.forEach(async ln => {
          let line: Linea[] = [];
          this.albaranService.pag = 0;
          if (ln.articulo.cod.includes('ALB')) {
            const albaran = (await this.albaranService.buscarAlbaran(ln.articulo.cod)).albaran;
            if (!albaran) {
              this.ui.alertError('Error al obtenber el albaran relacionado con la factura');
              return;
            } 
            
            albaran.linea.forEach(async ln => {
              const art = await this.articulosService.getArticulo(ln.articulo);
              if (art.ok) {
                const articulo: Linea = {
                  cod: art.articulo.cod,
                  nombre: art.articulo.nombre,
                  articulo: art.articulo._id,
                  unidades: ln.unidades,
                  total: ln.total
                };
                line.push(articulo);
              }
            });
            
            setTimeout(async () => {
            // tslint:disable-next-line: max-line-length
            pdf.add(new Txt('Albarán: ' + albaran.numeroAlbaran).fontSize(15).bold().alignment('center').color('#547047').end);
    
            pdf.add(
              pdf.ln(3)
            );
    
            // Datos de empresa que factura
            pdf.add(new Stack([
              factura.cliente.nombreFiscal,
              'CIF: ' + factura.cliente.cif,
              'Dirección: ' + factura.cliente.direccion,
              'Localidad: ' + factura.cliente.localidad,
              'Provncia: ' + factura.cliente.provincia,
              'C.P.: ' + factura.cliente.cp,
              // 'Teléfono: ' + factura.cliente.telefono,
              // 'E-Mail: ' + factura.cliente.email,
            ]).fontSize(10).end);
            // Marco de cliente
            pdf.add(
              new Canvas([
                new Rect([5, 5], [250, 120]).lineColor('#547047').end
              ]).absolutePosition(300, 100).end
            );
    
            // Datos de empresa
            pdf.add(new Stack([
              'Datos de Cliente',
              ' ',
              factura.empresa.nombreFiscal,
              'CIF: ' + factura.empresa.cif,
              'G.R.U.:' + factura.empresa.gru,
              'Dirección: ' + factura.empresa.direccion,
              'Localidad: ' + factura.empresa.localidad,
              'Provncia: ' + factura.empresa.provincia,
              'C.P.: ' + factura.empresa.cp,
              'Teléfono: ' + factura.empresa.telefono,
              'E-Mail: ' + factura.empresa.email,
            ]).fontSize(10).absolutePosition(310, 90).end);
    
           //  pdf.add(await new Img(`${factura.empresa.logo}`).height(80).width(100).absolutePosition(430, 10).build());
    
            // Cuerpo de Factura
            pdf.add(
              pdf.ln(3)
            );
    
            if (albaran.autonomo) {
              let autoGru: string;
              if (albaran.autonomo.gru) {
                autoGru = `G.R.U.: ${albaran.autonomo.gru}`;
              } else {
                autoGru = '';
              }
              pdf.add(new Txt('Autonomo: ' + albaran.autonomo.nombre + ' ' + albaran.autonomo.apellido1 + ' ' + albaran.autonomo.apellido2 + ' NIF: ' + (albaran.autonomo.nif || '') + ' ' + autoGru)
                .fontSize(10).bold().alignment('center').color('#547047').end);
            }
            // tslint:disable-next-line: max-line-length
    
    
            pdf.add(
              pdf.ln(1)
            );
    
             pdf.add(new Table([
              ['COD.', 'NOMBRE', 'UNIDADES', 'BIDONES 60L']
            ]).widths([140, 200, 60, 70]).color('#547047').fontSize(10).alignment('center').bold().end);
    
            line.forEach(l => {
              let bidones = Math.trunc(l.unidades / 60);
              const restoBidones = l.unidades % 60;
              if (restoBidones > 0) {
                bidones++;
              }
              const ln = [
                l.cod,
                l.nombre,
                (l.unidades).toFixed(2) + 'L.',
                bidones + ' Und.'
              ];
              pdf.add(new Table([
                ln
              ]).widths([140, 200, 60, 70]).fontSize(9).margin(5).alignment('center').layout('noBorders').end);
            });
            
    
            pdf.add(
              pdf.ln(3)
            );
            // Total de albaran
            pdf.add(new Table([
              [`Total: ${(albaran.totalUnidades).toFixed(2)} L.`]
            ]).widths([80, 80, 60]).color('#547047').fontSize(10).alignment('right').bold().relativePosition(420, 0).end);
    
    
            pdf.add(
              pdf.ln(2)
            );
    
            if (factura.empresa.ler) {
              pdf.add(new Txt(`*Código LER ${factura.empresa.ler}`).color('#547047').fontSize(10).alignment('right').bold().end);
            }
    
            pdf.add(
              pdf.ln(2)
            );
    
            const textoLegal = factura.empresa.legal.replace('{{factura.empresa}}', factura.empresa.nombreFiscal.toLocaleUpperCase());
            const textoLegalFin = textoLegal.replace('{{cif}}', factura.empresa.cif);
    
            pdf.add(new Txt(`${textoLegalFin}`)
              .fontSize(10).bold().alignment('center').color('#547047').end);
    
            pdf.add(
              pdf.ln(2)
            );
    
            // Documento adjutno UCO
    
            pdf.add(new Txt(`Nota: Al firmar el presente documento, el signatario reconoce que los auditores de los entes de certificación, el personal de ISCC y los inspectores de entidades públicas (si corresponde) pueden comprobar que se hayan cumplido los requisitos confirmados en la presente declaración del documento adjunto. Los firmantes declaran que la información descrita en los apartados anteriores es correcta y que se han adoptado todas las precauciones necesarias para evitar riesgos para la salud pública o la salud animal.`)
              .fontSize(10).bold().alignment('center').color('#547047').pageBreak('after').end);
    
    
            pdf.add(new Txt('Autodeclaración ISCC para puntos de origen que produzcan aceite de cocina usado (UCO)').alignment('center').color('#547047')
              .fontSize(13).bold().end);
    
            pdf.add(
              pdf.ln(2)
            );
            pdf.add(new Table([
              ['Información sobre el punto de origen (ej. restaurante, empresa de catering, etc.):']
            ]).widths([500]).fontSize(10).bold().color('#547047').alignment('center').bold().end);
    
            pdf.add(new Table([
              ['Nombre:', factura.cliente.nombreFiscal],
              ['Dirección:', factura.cliente.direccion + ' ' + factura.cliente.localidad + ' ' + factura.cliente.cp + ' ' + factura.cliente.provincia],
              ['País:', 'España'],
              ['Teléfono:', '0034 - ' + factura.cliente.telefono],
              ['Destinatario del UCO (punto de recogida)', factura.empresa.nombreFiscal + ' GRU: ' + factura.empresa.gru]
            ]).widths([98, 393]).fontSize(10).alignment('center').end);
    
            pdf.add(new Table([
              ['La cantidad de UCOproducida por el punto de origen es de diez (10) o más toneladas métricas al mes*1:', 'No'],
              ['El UCOproducido por el punto de origen es total o parcialmente de origen animal*2', 'No'],
            ]).widths([393, 98]).fontSize(10).alignment('center').end);
    
            pdf.add(new Table([
              ['Firmando esta autodeclaración, el signatario confirma lo siguiente:']
            ]).widths([500]).fontSize(10).bold().color('#547047').alignment('center').bold().end);
    
            pdf.add(new Table([
              [`1. El UCOhace referencia al aceite y las grasas de origen vegetal o animal utilizados para cocinar comida destinada al consumo humano. Las entregas de UCO que cubre esta autodeclaración constan íntegramente de UCO y no se mezclan con otros aceites o grasas que no cumplan con la definición de UCO.
                
                      2. El UCOque cubre esta autodeclaración cumple con la definición de «residuo». Esto significa que el UCOes un material que el punto de origen desecha o pretende o tiene la obligación de desechar, y que el UCOno se ha modificado o contaminado intencionadamente para cumplir con esta definición.
                
                      3 .Hay disponible documentación de las cantidades de UCOentregadas.
                
                      4 .Se cumple con la legislación nacional aplicable en relación con la prevención y la gestión de residuos (por ejemplo, para el transporte, la supervisión, etc.).
                
                      5 .Los auditores de entes de certificación o de ISCC (podrán estar acompañados por un representante del punto de recogida) podrán examinar si lo indicado en esta autodeclaración es correcto in situ o poniéndose en contacto con el signatario (por ejemplo, por teléfono).6.La información contenida en esta autodeclaración podrá transmitirse y ser revisada por el ente de certificación del punto de recogida y por ISCC. Nota: El ente de certificación e ISCC mantienen la confidencialidad de todos los datos proporcionados en esta autodeclaración.`]
            ]).widths([500]).fontSize(10).alignment('justify').end);
    
            const date = new Date(albaran.fecha);
    
            if (!albaran.autorizado) {
              albaran.autorizado = {
                nombre: '',
                dni: '',
                firma: undefined
              };
    
            } else if (albaran.autorizado.firma) {
              pdf.add(await new Img(albaran.autorizado.firma).height(80).width(100).relativePosition(330, 18).build());
            }
            pdf.add(new Table([
              [`Lugar`, `Autoriza`],
              [`
              ${factura.cliente.provincia} a ${date.getDate()} del ${date.getMonth() + 1} del ${date.getFullYear()}
              `,
              `
              ${albaran.autorizado.nombre}, con DNI ${albaran.autorizado.dni}
        
        
        
        
              `]
            ]).widths([245, 245]).fontSize(10).alignment('center').end);
    
            pdf.add(
              pdf.ln(2)
            );
            pdf.add(new Txt(`*1. 10 (diez) toneladas métricas de UCOequivalen a aprox. 11,1 (once coma un) metros cúbicos / 11100 (once mil cien) litros / 2932 (dos mil novecientos treinta y dos) galones
              
                  *2. Si está marcado este campo, se asume que el UCOproducido por el punto de origenes (al menos parcialmente) de origen animal (por ejemplo, derivado del uso de tocino, mantequilla, sebo, etc.) y que el punto de recogida no puede vender el UCOde este punto de origen como «100% de origen vegetal». Si no está marcado este campo, significa que el punto de origen utiliza exclusivamente aceite vegetal (ej. aceite de colza o de girasol) y no aceite o grasas de origen animal para cocinar o freír.Nota:El aceite vegetal que se haya empleado para cocinar o freír carne y que, por tanto, contengauna parte inevitable de origen animal, podrá seguir considerándose como un «UCO100% de origen vegetal»`)
              .fontSize(8).alignment('justify').color('#547047').end);
    
            pdf.add(
              pdf.ln(2)
            );
    
            pdf.add(new Txt(`En caso de conflicto entre la versión en inglés y la versión traducida de este documento, se aplicará la versión en inglés y será válida para las partes implicadas en esta autodeclaración.
              
                  In the event of any conflict between the English language version and the translated version of this document, the English language version shall apply and be binding upon the parties involved in this self-declaration.`)
              .fontSize(6).alignment('justify').color('#547047').end);
    
            pdf.add(
              pdf.ln(1)
            );
    
            pdf.add(new Txt(`© ISCC System GmbH  Versión2.0 (a 1 de abril de 2020)`)
              .fontSize(6).alignment('justify').color('#547047').pageBreak('after').end);
            }, 300);
            }
        });
        
  

      
    } 

    setTimeout(() => {
      switch (accion) {
        case 'p':
          pdf.create().print();
          break;
        case 'o':
          pdf.create().open();
          break;
        case 's':
          // Creo fichero pdf y envio por correo
          pdf.create().getDataUrl(async c => {
            const envio = await this.emailService.enviarMail('Atufactura retirada de aceite', 'Se ha generado una autofactura a su nombre'
              , c, factura.cliente.email);
            if (envio) {
              this.ui.presentToast('Correo enviado');
            } else {
              this.ui.alertError('Error al enviar el correo, verifique la configuración');
            }
          });
          break;
        default:
          pdf.create().open();
          break;
      }
    }, 2000);

  }

  async generateAlbaranPdf(albaran: Albaran, empresa: Empresa, cliente: Cliente, autonomo: Autonomo, lineas: Linea[], valorado: boolean, accion?: string) {


    const pdf = new PdfMakeWrapper();

    pdf.info({
      title: 'albaran ' + albaran.numeroAlbaran,
      author: 'bioApp',
    });

    let pagado: string;
    if (albaran.facturado.estado) {
      pagado = 'Facturado';
      pdf.watermark(new Txt(pagado).color('red').fontSize(50).end);
    }

    // tslint:disable-next-line: max-line-length
    pdf.add(new Txt('Albarán: ' + albaran.numeroAlbaran).fontSize(15).bold().alignment('center').color('#547047').end);

    pdf.add(
      pdf.ln(3)
    );

    // Datos de empresa que factura
    pdf.add(new Stack([
      cliente.nombreFiscal,
      'CIF: ' + cliente.cif,
      'Dirección: ' + cliente.direccion,
      'Localidad: ' + cliente.localidad,
      'Provincia: ' + cliente.provincia,
      'C.P.: ' + cliente.cp,
    //  'Teléfono: ' + cliente.telefono,
    //  'E-Mail: ' + cliente.email,
    ]).fontSize(10).end);

    // Marco de cliente
    pdf.add(
      new Canvas([
        new Rect([5, 5], [250, 120]).lineColor('#547047').end
      ]).absolutePosition(300, 100).end
    );

    // Datos de empresa
    pdf.add(new Stack([
      'Datos de Cliente',
      ' ',
      empresa.nombreFiscal,
      'CIF: ' + empresa.cif,
      'G.R.U.:' + empresa.gru,
      'Dirección: ' + empresa.direccion,
      'Localidad: ' + empresa.localidad,
      'Provincia: ' + empresa.provincia,
      'C.P.: ' + empresa.cp,
      'Teléfono: ' + empresa.telefono,
      'Email: ' + empresa.email,
    ]).fontSize(10).absolutePosition(310, 90).end);

    pdf.add(await new Img(`${empresa.logo}`).height(80).width(100).absolutePosition(430, 10).build());

    // Cuerpo de Factura
    pdf.add(
      pdf.ln(3)
    );


    if (autonomo) {
      let autoGru: string;
      if (autonomo.gru) {
        autoGru = `G.R.U.: ${autonomo.gru}`;
      } else {
        autoGru = '';
      }
      pdf.add(new Txt('Autonomo: ' + autonomo.nombre + ' ' + autonomo.apellido1 + ' ' + autonomo.apellido2 + ' NIF: ' + autonomo.nif + ' ' + autoGru)
        .fontSize(10).bold().alignment('center').color('#547047').end);
    }
    // tslint:disable-next-line: max-line-length


    pdf.add(
      pdf.ln(1)
    );
    if (valorado) {
      pdf.add(new Table([
        ['COD.', 'NOMBRE', 'UNIDADES', 'BIDONES 60L', 'TOTAL']
      ]).widths([80, 190, 60, 60, 70]).color('#547047').fontSize(10).alignment('center').bold().end);

      lineas.forEach(l => {
        let bidones = Math.trunc(l.unidades / 60);
        const restoBidones = l.unidades % 60;
        if (restoBidones > 0) {
          bidones++;
        }
        const ln = [
          l.cod,
          l.nombre,
          (l.unidades).toFixed(2) + ' L.',
          bidones + 'Und.',
          l.total.toFixed(2) + ' €'
        ];
        pdf.add(new Table([
          ln
        ]).widths([80, 190, 60, 60, 70]).fontSize(9).margin(5).alignment('center').layout('noBorders').end);
      });

      pdf.add(
        pdf.ln(3)
      );

      // Total de albaran
      pdf.add(new Table([
        [`Total: ${(albaran.total).toFixed(2)} €.`]
      ]).widths([80, 80, 60]).color('#547047').fontSize(10).alignment('right').bold().relativePosition(420, 0).end);

    } else {
      pdf.add(new Table([
        ['COD.', 'NOMBRE', 'UNIDADES', 'BIDONES 60L']
      ]).widths([140, 200, 60, 70]).color('#547047').fontSize(10).alignment('center').bold().end);

      lineas.forEach(l => {
        let bidones = Math.trunc(l.unidades / 60);
        const restoBidones = l.unidades % 60;
        if (restoBidones > 0) {
          bidones++;
        }
        const ln = [
          l.cod,
          l.nombre,
          (l.unidades).toFixed(2) + 'L.',
          bidones + ' Und.'
        ];
        pdf.add(new Table([
          ln
        ]).widths([140, 200, 60, 70]).fontSize(9).margin(5).alignment('center').layout('noBorders').end);
      });

      pdf.add(
        pdf.ln(3)
      );

      // Total de albaran
      pdf.add(new Table([
        [`Total: ${(albaran.totalUnidades).toFixed(2)} L.`]
      ]).widths([80, 80, 60]).color('#547047').fontSize(10).alignment('right').bold().relativePosition(420, 0).end);

    }


    pdf.add(
      pdf.ln(2)
    );

    if (empresa.ler) {
      pdf.add(new Txt(`*Código LER ${empresa.ler}`).color('#547047').fontSize(10).alignment('right').bold().end);
    }

    pdf.add(
      pdf.ln(2)
    );

    const textoLegal = empresa.legal.replace('{{empresa}}', empresa.nombreFiscal.toLocaleUpperCase());
    const textoLegalFin = textoLegal.replace('{{cif}}', empresa.cif);

    pdf.add(new Txt(`${textoLegalFin}`)
      .fontSize(10).bold().alignment('center').color('#547047').end);

    pdf.add(
      pdf.ln(2)
    );

    
    pdf.add(new Txt(`Nota: Al firmar el presente documento, el signatario reconoce que los auditores de los entes de certificación, el personal de ISCC y los inspectores de entidades públicas (si corresponde) pueden comprobar que se hayan cumplido los requisitos confirmados en la presente declaración del documento adjunto. Los firmantes declaran que la información descrita en los apartados anteriores es correcta y que se han adoptado todas las precauciones necesarias para evitar riesgos para la salud pública o la salud animal.`)
    .fontSize(10).bold().alignment('center').color('#547047').pageBreak('after').end);
    
    // Documento adjunto UCO

    pdf.add(await new Img("assets/iscc.png").height(30).width(80).relativePosition(220, 0).build());

    pdf.add(
      pdf.ln(2)
    );

    pdf.add(new Txt('Autodeclaración sobre aceites y grasas de cocina usados para la producción de biocombustible de acuerdo con la directiva 2009/28 CE sometida a ISCC UE').alignment('center').color('#000000')
      .fontSize(11).bold().end);

    pdf.add(
      pdf.ln(1)
    );

    pdf.add(new Txt('Nº de contrato / Nº acuerdo: __________________________________________').alignment('left').color('#000000')
      .fontSize(9).bold().end);

    pdf.add(
      pdf.ln(1)
    );
    
    // pdf.add(new Txt('Receptor/Gestor: Residuos y Reciclados Hispalenses, S.L. (CIF B-90.212.044)').alignment('left').color('#000000')
    //   .fontSize(9).bold().end);
    pdf.add(new Txt('Receptor/Gestor: ' + empresa.nombreFiscal + ' (CIF: ' + empresa.cif + ')').alignment('left').color('#000000')
      .fontSize(9).bold().end);

    pdf.add(new Txt('Entrada obligatoria si la autodeclaración se aplica a todas las entregas de un contrato o acuerdo.').alignment('left').color('#000000')
      .fontSize(9).bold().end);

    pdf.add(
      pdf.ln(1)
    );

    pdf.add(new Table([
      ['Información sobre el punto de origen (ej. restaurante, empresa de catering, etc.):']
    ]).widths([500]).fontSize(10).bold().color('#000000').alignment('center').bold().end);

    pdf.add(new Table([
      ['Nombre:', cliente.nombreFiscal],
      ['Dirección, calle:', cliente.direccion],
      ['C.Postal, ubicación:', cliente.cp + ' ' + cliente.localidad + ', ' + cliente.provincia],
      ['País:', 'ESPAÑA'],
      ['Nº de Teléfono:', '0034 - ' + (cliente.telefono || '')]
    ]).widths([98, 393]).fontSize(10).alignment('center').end);

    pdf.add(await new Img('assets/box.png').height(17).width(17).absolutePosition(526, 270.5).build());
    pdf.add(await new Img('assets/box.png').height(17).width(17).absolutePosition(526, 287).build());

    pdf.add(new Table([
      ['La cantidad de UCO producida por el punto de origen es de diez (10) o más toneladas métricas al mes¹', '' ],
      ['El UCO producido por el punto de origen es total o parcialmente de origen animal²', ''],
    ]).widths([470, 21]).fontSize(10).alignment('center').end);

    
    // CAMBIADO POR PETICIÓN DEL CLIENTE
    // pdf.add(new Table([
    //   ['Destinatario del UCO        (punto de recogida)', empresa.nombreFiscal + ' GRU: ' + empresa.gru]
    // ]).widths([125, 366]).fontSize(10).alignment('center').end);

    pdf.add(new Table([
      ['Destinatario del UCO        (punto de recogida)', '']
    ]).widths([125, 366]).fontSize(10).alignment('center').end);

    pdf.add(new Table([
      ['Firmando esta autodeclaración, el signatario confirma lo siguiente:']
    ]).widths([500]).fontSize(10).bold().color('#000000').alignment('center').bold().end);

    pdf.add(new Table([
      [`1. El UCO hace referencia al aceite y las grasas de origen vegetal o animal utilizados para cocinar comida destinada al consumo humano. Las entregas de UCO que cubre esta declaración constan íntegramente de UCO y no se mezclan con otros aceites o grasas que no cumplan con la definición de UCO.

      2. El UCO que cubre esta autodeclaración cumple con la definición de «residuo». Esto significa que el UCO es un material que el punto de origen desecha o pretende o tiene la obligación de desechar, y que el UCO no se ha modificado o contaminado intencionadamente para cumplir con esta definición.

      3. Hay disponible documentación de las cantidades de UCO entregadas.

      4. Se cumple con la legislación nacional aplicable en relación con la prevención y la gestión de residuos (por ejemplo, para el transporte, la supervisión, etc.).

      5. Los auditores de entes de certificación o de ISCC (podrán estar acompañados por un representante del punto de recogida) podrán examinar si lo indicado en esta autodeclaración es correcto in situ o poniéndose en contacto con el signatario (por ejemplo, por teléfono).

    6. La información contenida en esta autodeclaración podrá transmitirse y ser revisada por el ente de certificación del punto de recogida y por ISCC. Nota: El ente de certificación e ISCC mantienen la confidencialidad de todos los datos proporcionados en esta autodeclaración.`]
    ]).widths([500]).fontSize(9).alignment('justify').end);

    const date = new Date(albaran.fecha);
    if (!albaran.autorizado) {
      albaran.autorizado = {
        nombre: '',
        dni: '',
        firma: undefined
      };

    } else if (albaran.autorizado.firma) {
      pdf.add(await new Img(albaran.autorizado.firma).height(80).width(100).relativePosition(330, 18).build());
    }

    pdf.add(new Table([
      [`
      
      ${cliente.provincia} a ${date.getDate()} del ${date.getMonth() + 1} del ${date.getFullYear()}

      `,
      `
      
      
      `],
      [`Lugar, fecha`, `Firma`]
    ]).widths([245, 245]).fontSize(10).alignment('center').end);

    // MODELO DE CUADRO DE FIRMA CON DATOS DEL CLIENTE - ELIMINADO TEMPORALMENTE POR PETICION DEL CLIENTE
    // pdf.add(new Table([
    //   [`

      
    //   ${cliente.provincia} a ${date.getDate()} del ${date.getMonth() + 1} del ${date.getFullYear()}
    //   `,
    //   `
    //   ${albaran.autorizado.nombre}, con DNI ${albaran.autorizado.dni}
      
      
      
      
    //   `],
    //   [`Lugar, fecha`, `Firma`]
    // ]).widths([245, 245]).fontSize(10).alignment('center').end);

    pdf.add(
      pdf.ln(1)
    );

    pdf.add(new Txt(`¹ 10 (diez) toneladas métricas de UCO equivalen a aprox. 11,1 (once coma un) metros cúbicos / 11100 (once mil cien) litros / 2932 (dos mil novecientos treinta y dos) galones.

    ² Si está marcado este campo, se asume que el UCO producido por el punto de origen es (al menos parcialmente) de origen animal (por ejemplo, derivado del uso de tocino, mantequilla, sebo, etc.) y que el punto de recogida no puede vender el UCO de este punto de origen como «100% de origen vegetal». Si no está marcado este campo, significa que el punto de origen utiliza exclusivamente aceite vegetal (ej. aceite de colza o de girasol) y no aceite o grasas de origen animal para cocinar o freír.
    Nota: El aceite vegetal que se haya empleado para cocinar o freír carne y que, por tanto, contenga una parte inevitable de origen animal, podrá seguir considerándose como un «UCO 100% de origen vegetal»`)
      .fontSize(7).alignment('justify').color('#000000').end);

    pdf.add(
      pdf.ln(1)
    );

    pdf.add(new Txt(`En caso de conflicto entre la versión en inglés y la versión traducida de este documento, se aplicará la versión en inglés y será válida para las partes implicadas en esta autodeclaración.

    In the event of any conflict between the English language version and the translated version of this document, the English language version shall apply and be binding upon the parties involved in this self-declaration.`)
      .fontSize(6).alignment('justify').color('#000000').end);

    pdf.add(
      pdf.ln(1)
    );

    pdf.add(new Txt(`© ISCC System GmbH  Versión 2.0 (a 1 de abril de 2020)`)
      .fontSize(6).alignment('justify').color('#000000').end);


    switch (accion) {
      case 'p':
        pdf.create().print();
        break;
      case 'o':
        pdf.create().open();
        break;
      default:
        pdf.create().open();
        break;
    }

  }

  generateTablaPagosPdf(titulo: string, tabla: any[], total: number, iva: number, accion?: string) {
    const pdf = new PdfMakeWrapper();

    pdf.info({
      title: 'listadoPagos',
      author: 'bioApp',
    });

    pdf.add(
      new Txt(titulo).alignment('center').italics().bold().fontSize(20).end
    );
    pdf.add(new Txt('Total pagado: ' + total + '€ Impuestos totales: ' + iva + '€').alignment('center').italics().fontSize(12).end);
    pdf.add(
      pdf.ln(2)
    );

    tabla.forEach(t => {
      pdf.add(new Table([
        t
      ]).widths(['*', '*', 60, '*', 50, 60]).end); 9
    });

    switch (accion) {
      case 'p':
        pdf.create().print();
        break;
      case 'o':
        pdf.create().download();
        break;
      default:
        pdf.create().open();
        break;
    }
  }

  generateTablaAlbaranesPdf(titulo: string, tabla: any[], total: number, accion?: string) {
    const pdf = new PdfMakeWrapper();

    pdf.info({
      title: 'listadoAlbaranes',
      author: 'bioApp',
    });

    pdf.add(
      new Txt(titulo).alignment('center').italics().bold().fontSize(20).end
    );
    pdf.add(new Txt('Total albaranado: ' + total + '€').alignment('center').italics().fontSize(12).end);
    pdf.add(
      pdf.ln(2)
    );

    tabla.forEach(t => {
      pdf.add(new Table([
        t
      ]).widths([70, '*', '*', 100, 60, 50, 20]).fontSize(10).end);
    });

    switch (accion) {
      case 'p':
        pdf.create().print();
        break;
      case 'o':
        pdf.create().download();
        break;
      default:
        pdf.create().open();
        break;
    }
  }

  generateTablaFacturasPdf(titulo: string, tabla: any[], total: number, iva: number, accion?: string) {
    const pdf = new PdfMakeWrapper();

    pdf.info({
      title: 'listadoFacturas',
      author: 'bioApp',
    });

    pdf.add(
      new Txt(titulo).alignment('center').italics().bold().fontSize(20).end
    );
    pdf.add(new Txt('Total facturado: ' + total + '€').alignment('center').italics().fontSize(12).end);
    pdf.add(
      pdf.ln(2)
    );

    tabla.forEach(t => {
      pdf.add(new Table([
        t
      ]).widths([75, 65, 80, 80, 50, 40, 50, 15]).fontSize(10).end);
    });

    switch (accion) {
      case 'p':
        pdf.create().print();
        break;
      case 'o':
        pdf.create().download();
        break;
      default:
        pdf.create().open();
        break;
    }
  }


  generateTablaCargas(titulo: string, tabla: any[], total: number, accion: string) {
    const pdf = new PdfMakeWrapper();

    pdf.info({
      title: 'listadoFacturas',
      author: 'bioApp',
    });

    pdf.add(
      new Txt(titulo).alignment('center').italics().bold().fontSize(20).end
    );
    pdf.add(new Txt('Total cargado en GRU: ' + total + 'L').alignment('center').italics().fontSize(12).end);
    pdf.add(
      pdf.ln(2)
    );

    tabla.forEach(t => {
      pdf.add(new Table([
        t
      ]).widths(['*', '*', '*', '*', '*']).fontSize(10).end);
    });

    switch (accion) {
      case 'p':
        pdf.create().print();
        break;
      case 'o':
        pdf.create().download();
        break;
      default:
        pdf.create().open();
        break;
    }
  }

  generateSabana(sbn: Sabana, items: Item[]){
    const pdf = new PdfMakeWrapper();

    pdf.info({
      title: 'sabana',
      author: 'bioApp',
    });

    const fecha = new Date();
    pdf.add(
      new Txt(sbn.cliente.nombreFiscal).alignment('center').italics().bold().fontSize(20).end
    );
    pdf.add(
      new Txt(`REGISTRO: ${sbn.annio}`).alignment('center').italics().fontSize(15).end
    );
    pdf.add(
      new Txt(`Impreso: ${fecha.getDate()}-${fecha.getMonth() +1}-${fecha.getFullYear()}`).alignment('center').italics().fontSize(10).end
    );
     
    pdf.add(
      pdf.ln(2)
    );

    pdf.add(new Table([
      ['Litros', 'Kilos', 'Base', 'IVA', 'Total'],
      [sbn.totalLitros, sbn.totalLitros * 0.9, sbn.totalBase.toFixed(2), (sbn.totalIva - sbn.totalBase).toFixed(2), sbn.totalIva.toFixed(2)]
    ]).widths(['*', '*', '*', '*', '*']).end);

    pdf.add(
      pdf.ln(1)
    );

    pdf.add(new Table([
      ['MES', 'Litros', 'Kilos', 'Total']
    ]).widths(['*','*','*','*']).end);

    items.forEach(i => {
      pdf.add(new Table([
        [i.mes, i.litros, i.kilos, i.total]
      ]).widths(['*','*','*','*']).layout('noBorders').alignment('center').end);

    });

    setTimeout(() => {
      pdf.create().download();
    }, 200);
  }
}
