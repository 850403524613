import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { AutonomosService } from '../../services/autonomos.service';
import { Autonomo } from '../../interfaces/autonomo.inter';
import { UiSereviceService } from '../../services/ui-serevice.service';


@Component({
  selector: 'app-modal-autonomos',
  templateUrl: './modal-autonomos.component.html',
  styleUrls: ['./modal-autonomos.component.scss'],
})
export class ModalAutonomosComponent implements OnInit {

  autonomos: Autonomo[] = [];
  buscarText: string;
  constructor(private modalController: ModalController,
              private autonomosService: AutonomosService,
              private ui: UiSereviceService) { }

  async ngOnInit() {
      const resp = await this.autonomosService.getAutonomos(true);
      if (resp.ok){
        this.autonomos = resp.autonomos;
      } else {
        this.ui.alertError('No se han cargado los datos desde el backen: Error Autonomos');
      }
  }

  async buscar(event){
    const auton = await this.autonomosService.buscarAutonomo(event.detail.value);
    if (!auton.ok){
      this.ui.alertError('Error al realizar la busqueda');
      return
    }
    this.autonomos = auton.autonomos;
  }

  cerrar(){
    this.modalController.dismiss();
  }
  select(auto: Autonomo){
    this.modalController.dismiss(auto);
  }
}
