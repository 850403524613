import { Pipe, PipeTransform } from '@angular/core';
import { Articulo } from '../interfaces/articulo.inter';

@Pipe({
  name: 'buscarArticulo'
})
export class BuscarArticuloPipe implements PipeTransform {

  transform(articulos: Articulo[], text: string): Articulo[] {
    if (text) { text = text.toLowerCase(); }
    if (!text){
      return articulos;
    }
    return articulos.filter(art => {
        return art.cod.toLowerCase().includes(text) || art.nombre.toLowerCase().includes(text);
      });
  }
}
