import { Pipe, PipeTransform } from '@angular/core';
import { Notificacion } from '../interfaces/notificacion.inter';

@Pipe({
  name: 'notificacion'
})
export class NotificacionPipe implements PipeTransform {

  transform(buscar: Notificacion[], text: string): Notificacion[] {

    if (text) { text = text.toLowerCase(); }
    if (!text){
      return buscar;
    } else {
      return buscar.filter(busqueda => {
        // tslint:disable-next-line: max-line-length
        return busqueda.titulo.toLowerCase().includes(text);
      });
    }
  }

}
