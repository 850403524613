import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Autonomo, Push } from '../../interfaces/autonomo.inter';
import { ModalAutonomosComponent } from '../modal-autonomos/modal-autonomos.component';
import { PushService } from '../../services/push.service';
import { ModalPushActivosComponent } from '../modal-push-activos/modal-push-activos.component';

@Component({
  selector: 'app-nueva-notificacion',
  templateUrl: './nueva-notificacion.component.html',
  styleUrls: ['./nueva-notificacion.component.scss'],
})
export class NuevaNotificacionComponent implements OnInit {

  todos = false;
  push: Push = {};
  tokenId: string[] = [];
  pushs: Push[] = [];
  autonomos: string[] = [];
  titulo = '';
  mensaje = '';

  constructor( private modalController: ModalController, private pushSercive: PushService) { }

  ngOnInit() {}

  async addAutonomo(){
    const modal = await this.modalController.create({
      component: ModalPushActivosComponent,
      cssClass: 'art-modal',
      backdropDismiss: false
    });

    await modal.present();
    const { data } = await modal.onWillDismiss();
    if (data){
      const push: Push = {
        autonomo: data.autonomo,
        terminales: data.terminales,
        fechaAlta: data.fechaAlta
      };
      data.terminales.forEach(t => {
        this.tokenId.push(t.tokenId);
      });
      this.pushs.unshift(push);
     }
  }
  cerrar(){
    this.modalController.dismiss();
  }
  async enviar(){
    this.pushs.forEach(p => {
      this.autonomos.push(p.autonomo._id);
    });

    const data = {
      autonomos: this.autonomos,
      pushs: this.tokenId,
      mensaje: this.mensaje,
      titulo: this.titulo,
      todos: this.todos
    };

    this.modalController.dismiss(data);
  }
}
