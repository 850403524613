import { Component, Input} from '@angular/core';
import { SabanaService } from '../../services/sabana.service';
import { AlertController } from '@ionic/angular';
import { Item } from 'src/app/interfaces/sabana.inter';
import { PdfPrinterService } from '../../services/pdf-printer.service';
import { ExcelService } from '../../services/excel.service';
import { Cliente } from '../../interfaces/cliente.inter';
import { Sabana } from '../../interfaces/sabana.inter';

@Component({
  selector: 'app-sabana-comp',
  templateUrl: './sabana.component.html',
  styleUrls: ['./sabana.component.scss'],
})
export class SabanaComponent  {

  @Input() sbn: Sabana = {};
  estado: boolean = true;
  annio: '';
  run = false;
  items: Item[] = [
    {
      numero: 1,
      mes: 'ENERO',
      litros: '',
      kilos: '',
      total: '',
      iva: ''
    },
    {
      numero: 2,
      mes: 'FEBRERO',
      litros: '',
      kilos: '',
      total: '',
      iva: ''
    },
    {
      numero: 3,
      mes: 'MARZO',
      litros: '',
      kilos: '',
      total: '',
      iva: ''
    },
    {
      numero: 4,
      mes: 'ABRIL',
      litros: '',
      kilos: '',
      total: '',
      iva: ''
    },
    {
      numero: 5,
      mes: 'MAYO',
      litros: '',
      kilos: '',
      total: '',
      iva: ''
    },
    {
      numero: 6,
      mes: 'JUNIO',
      litros: '',
      kilos: '',
      total: '',
      iva: ''
    },
    {
      numero: 7,
      mes: 'JULIO',
      litros: '',
      kilos: '',
      total: '',
      iva: ''
    },
    {
      numero: 8,
      mes: 'AGOSTO',
      litros: '',
      kilos: '',
      total: '',
      iva: ''
    },
    {
      numero: 9,
      mes: 'SEPTIEMBRE',
      litros: '',
      kilos: '',
      total: '',
      iva: ''
    },
    {
      numero: 10,
      mes: 'OCTUBRE',
      litros: '',
      kilos: '',
      total: '',
      iva: ''
    },
    {
      numero: 11,
      mes: 'NOVIEMBRE',
      litros: '',
      kilos: '',
      total: '',
      iva: ''
    },
    {
      numero: 12,
      mes: 'DICIEMBRE',
      litros: '',
      kilos: '',
      total: '',
      iva: ''
    },
  ]
  constructor (
        private sabanaService: SabanaService,
        private pdfService: PdfPrinterService,
        private xlsService: ExcelService,
        private alertCtrl: AlertController) { }


  onClick() {
    this.estado = !this.estado;
  }

  despliega(estado: boolean): boolean{
    if (!estado && !this.run){
      this.obtieneDatos();      
      this.run = true;
    }
    return !estado;
  }

  async export(){
    this.obtieneDatos();
    const alert = await this.alertCtrl.create({
      cssClass: 'my-custom-class',
      header: 'Exportar sábana',
      message: 'Formato para la exportación!!!',
      buttons: [
        {
          text: 'PDF',
          cssClass: 'secondary',
          handler: () => {
            this.pdfService.generateSabana(this.sbn, this.items);
          }
        }, {
          text: 'XLSX',
          handler: () => {            
            this.xlsService.exportAsExcelFile(this.generateXls(), 'Sabana');
          }
        }
      ]
    });

    await alert.present();
  }

  private obtieneDatos(){
    const midel = this.sbn.annio.split('-');
    this.items.forEach( async i => {
      const totalMes = await this.sabanaService.getSabanaMes(this.sbn.cliente._id, i.numero, midel[1]);
      i.litros = totalMes.litros.toFixed(2);
      i.total = totalMes.total.toFixed(2);
      i.kilos = (totalMes.litros * 0.9).toFixed(2);
      i.iva = (totalMes.total - (totalMes.total / 1.21)).toFixed(2);

    });
  }

  private generateXls(): any{
    let sabanaXls: any[] = [];
    let sabana = {
      cleinte: this.sbn.cliente.nombreFiscal,
      registro: this.sbn.annio,
      litros: this.sbn.totalLitros,
      kilos: this.sbn.totalLitros * 0.90,
      base: this.sbn.totalBase,
      iva: this.sbn.totalIva - this.sbn.totalBase,
      total: this.sbn.totalIva
    }
    sabanaXls.push(sabana);
    this.items.forEach(i => {
      const mes = {
        mes: i.mes,
        litros: Number(i.litros),
        kilos: Number(i.litros) * 0.90,
        base: Number(i.total) - Number(i.iva),
        iva: Number(i.iva),
        total: Number(i.total)
      }
      sabanaXls.push(mes);
    });
    return sabanaXls;
  }

}


