import { Component, OnInit } from '@angular/core';
import { PopoverController } from '@ionic/angular';

@Component({
  selector: 'app-pop-config',
  templateUrl: './pop-config.component.html',
  styleUrls: ['./pop-config.component.scss'],
})
export class PopConfigComponent implements OnInit {

  botones = [
    {
      nombre: 'Usuarios',
      accion: 'usuarios',
      icon: 'person-outline'
    },
    {
      nombre: 'Empresa',
      accion: 'empresa',
      icon: 'business-outline'
    },
    {
      nombre: 'Contadores',
      accion: 'contadores',
      icon: 'grid-outline'
    },
    {
      nombre: 'Configuración',
      accion: 'conf',
      icon: 'construct-outline'
    }
  ];


  constructor(private popoverController: PopoverController) { }

  ngOnInit() {}
  onClick(accion: string){
    this.popoverController.dismiss(accion);
  }
}
