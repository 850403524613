import { Pipe, PipeTransform } from '@angular/core';
import { Cliente } from '../interfaces/cliente.inter';

@Pipe({
  name: 'buscarCliente'
})
export class BuscarClientePipe implements PipeTransform {

  transform(clientes: Cliente[], text: string): Cliente[] {
    if (text) { text = text.toLowerCase(); }
    if (!text){
      return clientes;
    } else {
      return clientes.filter(cli => {
        return cli.nombre.toLowerCase().includes(text) || cli.nombreFiscal.toLowerCase().includes(text);
      });
    }
  }
}
