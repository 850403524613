import { Pipe, PipeTransform } from '@angular/core';
import { Empresa } from '../interfaces/empresa.inter';

@Pipe({
  name: 'buscarEmpresa'
})
export class BuscarEmpresaPipe implements PipeTransform {

  transform(empresas: Empresa[], text: string): Empresa[] {
    if (text) { text = text.toLowerCase(); }
    if (!text){
      return empresas;
    } else {
      return empresas.filter( emp => {
        return emp.nombreComercial.toLowerCase().includes(text) || emp.nombreFiscal.toLowerCase().includes(text);
      });
    }
  }

}
