import { Component } from '@angular/core';
import { Platform, NavController, ModalController, PopoverController } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { LoginPage } from './pages/login/login.page';
import { UiSereviceService } from './services/ui-serevice.service';
import { PopConfigComponent } from './components/pop-config/pop-config.component';
import { Usuario } from './interfaces/usuario.inter';
import { LoginService } from './services/login.service';
import { ModalConfigComponent } from './components/modal-config/modal-config.component';
import { FormEmpresaPage } from './pages/empresa/form-empresa/form-empresa.page';


@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent {


  menus = [
    {
      nombre: 'Inicio',
      ico: 'home',
      permiso: 1,
      fw: '/inicio'
    },
    {
      nombre: 'Autónomos',
      ico: 'body',
      permiso: 1,
      fw: '/autonomos'
    },
    {
      nombre: 'Gestión Residuos',
      ico: 'leaf-outline',
      permiso: 2,
      fw: '/gru'
    },
    {
      nombre: 'Clientes',
      ico: 'people-outline',
      permiso: 1,
      fw: '/clientes'
    },
    {
      nombre: 'Artículos',
      ico: 'pricetags-outline',
      permiso: 2,
      fw: '/articulos'
    },
    {
      nombre: 'Albaranes',
      ico: 'file-tray-stacked-outline',
      permiso: 1,
      fw: 'albaranes'
    },
    {
      nombre: 'Sábana',
      ico: 'albums-outline',
      permiso: 1,
      fw: 'sabana'
    },
    {
      nombre: 'Tickets',
      ico: 'receipt-outline',
      permiso: 1,
      fw: 'tickets'
    },
    {
      nombre: 'Facturas',
      ico: 'reader-outline',
      permiso: 2,
      fw: 'facturas'
    },
    {
      nombre: 'Pagos',
      ico: 'wallet-outline',
      permiso: 3,
      fw: 'pagos'
    },
    {
      nombre: 'Notificaciones',
      ico: 'mail-outline',
      permiso: 2,
      fw: 'notificaciones'
    }
  ];

  usuario: Usuario = {};
  permiso: number;
  loginOk = false;

  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private navController: NavController,
    private modalController: ModalController,
    private loginService: LoginService,
    private ui: UiSereviceService,
    private popoverController: PopoverController) {

      this.initializeApp();
    }

    async initializeApp() {
      this.platform.ready().then(() => {
        this.statusBar.styleDefault();
        this.splashScreen.hide();
      });

      this.login();
  }

  async login() {

    const modal = await this.modalController.create({
      component: LoginPage,
      cssClass: '',
      componentProps: {
        estadoLogin: false
      }
    });

    await modal.present();
    const { data } = await modal.onDidDismiss();
    if (data){
      this.loginOk = true;
      this.usuario = await this.loginService.cargarUsuario();
      if (this.usuario.permiso === 'SUPER'){
        this.permiso = 3;
      }
      if (this.usuario.permiso === 'ADMIN'){
        this.permiso = 2;
      }
      if (this.usuario.permiso === 'USER'){
        this.permiso = 1;
      }
      this.navController.navigateRoot('/inicio');
    } else {
      this.ui.alertError('Usuario o contraseña incorrecta!!!');
    }
  }

  onClick(menu){
    this.navController.navigateForward(menu.fw);
  }

  async menu(){
    const pop = await this.popoverController.create({
      component: PopConfigComponent,
      event,
      mode: 'ios'
    });

    await pop.present();
    const { data } = await pop.onWillDismiss();
    switch (data){
      case 'conf':

        const modal = await this.modalController.create({
          component: ModalConfigComponent,
          cssClass: 'conf-modal'
        });
        await modal.present();
        break;
      case 'usuarios':
        this.navController.navigateForward('usuarios');
      break;
      case 'contadores':
        this.navController.navigateForward('contadores');
      break;
      case 'empresa':
          const modalEmp = await this.modalController.create({
            component: FormEmpresaPage,
            cssClass: 'emp-modal',
            componentProps: {
              titulo: 'Mantenimiento de empresa'
            }
          });
          await modalEmp.present();
      break;
    }
  }
}

