import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BuscarNombrePipe } from './buscar-nombre.pipe';
import { BuscarArticuloPipe } from './buscar-articulo.pipe';
import { BuscarClientePipe } from './buscar-cliente.pipe';
import { BuscarEmpresaPipe } from './buscar-empresa.pipe';
import { LogoPipePipe } from './logo-pipe.pipe';
import { NotificacionPipe } from './notificacion.pipe';



@NgModule({
  declarations: [
    BuscarNombrePipe,
    BuscarArticuloPipe,
    BuscarClientePipe,
    BuscarEmpresaPipe,
    LogoPipePipe,
    NotificacionPipe,
    NotificacionPipe],
  imports: [
    CommonModule
  ],
  exports: [BuscarNombrePipe, BuscarArticuloPipe, BuscarClientePipe,  BuscarEmpresaPipe, LogoPipePipe, NotificacionPipe]
})
export class PipesModule { }
