import { Component, OnInit, Input } from '@angular/core';
import { Albaran, Linea } from 'src/app/interfaces/albaranes.inter';
import { Autonomo } from 'src/app/interfaces/autonomo.inter';
import { Empresa } from 'src/app/interfaces/empresa.inter';
import { Cliente } from 'src/app/interfaces/cliente.inter';
import { ModalController, AlertController } from '@ionic/angular';
import { UiSereviceService } from 'src/app/services/ui-serevice.service';
import { EmpresasService } from 'src/app/services/empresas.service';
import { AutonomosService } from 'src/app/services/autonomos.service';
import { ArticulosService } from 'src/app/services/articulos.service';
import { ClientesService } from 'src/app/services/clientes.service';
import { isTabSwitch } from '@ionic/angular/directives/navigation/stack-utils';
import { AlbaranesService } from '../../services/albaranes.service';
import { HuecosFacuraComponent } from '../huecos-facura/huecos-facura.component';
import { PdfPrinterService } from '../../services/pdf-printer.service';
import { Factura } from '../../interfaces/factura.inter';
import { NumberValueAccessor } from '@angular/forms';
import { ThrowStmt } from '@angular/compiler';


@Component({
  selector: 'app-albaran',
  templateUrl: './albaran.component.html',
  styleUrls: ['./albaran.component.scss'],
})
export class AlbaranComponent implements OnInit {

  @Input() albaran: Albaran;
  autonomo: Autonomo = {};
  empresa: Empresa = {};
  cliente: Cliente = {};
  lineas: Linea[] = [];

  constructor(private modelaController: ModalController,
              private empresaService: EmpresasService,
              private autonomoService: AutonomosService,
              private albaranesService: AlbaranesService,
              private ui: UiSereviceService,
              private alertController: AlertController,
              private modalController: ModalController,
              private articulosService: ArticulosService,
              private clienteService: ClientesService,
              private pdfPrinterService: PdfPrinterService) { }

  ngOnInit() {
    this.obtenerDatos();
    this.obtenerArticulo(this.albaran.linea);
  }

  async obtenerDatos(){

    if (this.albaran.autonomo){

      const respAuto = await this.autonomoService.getAutonomo(this.albaran.autonomo);
      if (respAuto.ok){
        this.autonomo = respAuto.autonomo;
      } else {
          this.ui.alertError('Error: no se ha cargado el autonomo desde el Backend');
        }
    }
      const respEmp = await this.empresaService.getEmpresa(this.albaran.empresa);
      if (respEmp.ok){
        this.empresa = respEmp.empresa;
        const respCliente = await this.clienteService.getCliente(this.albaran.cliente);
        if (respCliente.ok){
          this.cliente = respCliente.cliente;
        } else {
        this.ui.alertError('Error: no se ha cargado el cliente desde el Backend');
        }
      } else {
        this.ui.alertError('Error: no se ha cargado la empresa desde el Backend');
      }
    } 


  obtenerArticulo(lineas: Linea[]) {
    lineas.forEach(async linea => {
      const art = await this.articulosService.getArticulo(linea.articulo._id);
      if (art.ok){
        const articulo: Linea = {
          cod: art.articulo.cod,
          nombre: art.articulo.nombre,
          articulo: art.articulo._id,
          unidades: linea.unidades,
          total: linea.total
        };
        this.lineas.push(articulo);
      }
    });
  }

  cerrar(){
    this.modelaController.dismiss();
  }

  async facturar(alb: Albaran, empId: string) {
    const alert = await this.alertController.create({
      cssClass: 'my-custom-class',
      header: 'Numero de AutoFactura!',
      inputs: [
        {
          name: 'nFactura',
          type: 'number',
          value: new Date().getMonth() +1,
          placeholder: 'Numero auto factura'
        }        
      ],
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          cssClass: 'secondary'
        }, {
          text: 'Ok',
          handler: (data) => {
            this.facturar2(alb, data.nFactura, empId);
          }
        }
      ]
    });

    await alert.present();
  }

  private async facturar2(alb: Albaran, nAfactura: number, empId: string){
    const albaran = alb;
    let hueco;
    const huecos = await this.albaranesService.huecosFactura(empId);
    if (huecos.ok) {
      const modal = await this.modalController.create({
        component: HuecosFacuraComponent,
        cssClass: 'autonomo-modal',
        backdropDismiss: false,
        componentProps: {
          huecos: huecos.huecos
        }
      });

      await modal.present();
      const { data } = await modal.onWillDismiss();
      if (data) {
        hueco = data;
      } else {
        hueco = undefined;
      }
    }
    const alert = await this.alertController.create({
      header: '¡Advertencia!',
      message: '<strong>¿Desea facturar el albaran?</strong>',
      inputs: [
        {
          name: 'radio1',
          type: 'radio',
          label: 'Factura Empresa',
          value: true,
          checked: true
        },
        {
          name: 'radio2',
          type: 'radio',
          label: 'Factura Autonomo',
          value: false
        }],
      buttons: [
        {
          text: 'Cancelar',
          role: 'cancel',
        },
        {
          text: 'Facturar',
          handler: async (empresa) => {
            const resp = await this.albaranesService.facturarAlbaran(albaran, empresa, nAfactura, hueco);
               if (resp.ok) {
              this.ui.presentToast('se ha facturado facturado el albaran: ' + albaran.numeroAlbaran);
            } else {
              this.ui.alertError(resp.error);
            }
          }
        },
        {
          text: 'Facturar e imprimir',
          handler: async (empresa) => {
            const resp = await this.albaranesService.facturarAlbaran(albaran, empresa, nAfactura, hueco);
            if (resp.ok) {
              this.ui.presentToast('se ha facturado facturado el albaran: ' + albaran.numeroAlbaran);
              this.imprimirFactura(resp.factura, 'p');
            } else {
              this.ui.presentToast('Error: No se ha facturado el albaran: ' + albaran.numeroAlbaran);
            }
          }
        },
        {
          text: 'Facturar y enviar por correo',
          handler: async (empresa) => {
            const resp = await this.albaranesService.facturarAlbaran(albaran, empresa, nAfactura, hueco);
            if (resp.ok) {
              this.ui.presentToast('se ha facturado facturado el albaran: ' + albaran.numeroAlbaran);
              this.imprimirFactura(resp.factura, 's');
            } else {
              this.ui.presentToast('Error: No se ha facturado el albaran: ' + albaran.numeroAlbaran);
            }
          }
        }
      ]
    });
    await alert.present();
  }

  async imprimirFactura(factura: Factura, accion: string){
    const lineas: any[] = await this.obtenerLineas(factura);
    setTimeout(() => {
      this.pdfPrinterService.generateFacturaPdf(factura, lineas, accion);
    }, 500);

 }

 obtenerLineas(factura: Factura){
   return new Promise<any[]>(resolve => {
     const lineas = [];
     factura.linea.forEach(async linea => {
       const art = await this.articulosService.getArticulo(linea.articulo);
       if (art.ok){
         const articulo: Linea = {
           cod: art.articulo.cod,
           nombre: art.articulo.nombre,
           articulo: art.articulo._id,
           unidades: linea.unidades,
           total: linea.total
         };
         lineas.push(articulo);
       }
     });
     resolve(lineas);
   });
 }

 confirmaValorado(){
   return new Promise<boolean>(async resolve => {
    const alert = await this.alertController.create({
      cssClass: 'my-custom-class',
      header: 'Valorar',
      message: '¿Desea que el albarán sea valorado?',
      buttons: [
        {
          text: 'NO',
          role: 'cancel',
          cssClass: 'secondary',
          handler: () => {
            resolve(false);
          }
        }, {
          text: 'SI',
          handler: () => {
            resolve(true);
          }
        }
      ]
    });

    await alert.present();
   });
 }

  async imprimir(){    
    const valorado = await this.confirmaValorado(); 
    this.pdfPrinterService.generateAlbaranPdf(this.albaran, this.empresa, this.cliente, this.albaran.autonomo,  this.lineas, valorado, 'p');
  }
}
