import { Component, OnInit, Input } from '@angular/core';
import { Hueco } from '../../interfaces/huecos.inter';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-huecos-facura',
  templateUrl: './huecos-facura.component.html',
  styleUrls: ['./huecos-facura.component.scss'],
})
export class HuecosFacuraComponent implements OnInit {

  @Input() huecos: Hueco[];
  buscarText: string;
  constructor( private modalController: ModalController) { }

  ngOnInit() {
  }

  buscar(event){

  }

  select(hueco: string){
    this.modalController.dismiss(hueco);
  }

  cerrar(){
    this.modalController.dismiss();
  }

}
