import { Component, OnInit, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-modal-fechas',
  templateUrl: './modal-fechas.component.html',
  styleUrls: ['./modal-fechas.component.scss'],
})
export class ModalFechasComponent implements OnInit {

  datei: Date;
  dateo: Date;
  intervalo: boolean;

  constructor( private modalController: ModalController) { }

  ngOnInit() {}

  cerrar(){
    this.modalController.dismiss();
  }

  buscar(){
    if (this.intervalo){
      this.modalController.dismiss([this.datei, this.dateo]);
    } else {
      this.modalController.dismiss(this.datei);
    }
  }

}
